import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchNewSpeeddatingRequests,
    selectSpeeddatingNewRequests,
    selectSpeeddatingRequestEditor
} from '../reducers/spedddatingRequests'
import {
    selectSpeeddatingRequestUserEditor
} from '../reducers/users'
import {fetchSpeeddatingPublicEvents, selectSpeeddatingPublicEvents} from '../reducers/spedddatingEvents'
import VisitorCardNewRequest from "../components/speeddatingManager/VisitorCardNewRequest";
import RequestEditorForm from "../components/speeddatingManager/RequestEditorForm";
import UserEditForm from "../components/speeddatingManager/UserEditForm";
import EventHistoryCard from "../components/speeddatingManager/EventHistoryCard";
import SpeeddatingManagerUserSearch from "../components/speeddatingManager/UserSearchForm";
import {SpeeddatingRequest} from "../types/spedddating";
import requestSortFunction from "../util/requestSortFunction";

export default function ManagerRequest() {

    const dispatch = useDispatch();
    const newRequests = useSelector(selectSpeeddatingNewRequests);
    const events = useSelector(selectSpeeddatingPublicEvents);
    const requestEditor = useSelector(selectSpeeddatingRequestEditor);
    const userEditor = useSelector(selectSpeeddatingRequestUserEditor);

    if (newRequests.state === 'unknown') {
        dispatch(fetchNewSpeeddatingRequests());
    }
    useEffect(() => {
        const timer = setTimeout(
            () => dispatch(fetchNewSpeeddatingRequests()),
            5000
        );
        return () => clearTimeout(timer);
    });

    if (events.state === 'unknown') {
        dispatch(fetchSpeeddatingPublicEvents());
    }

    return (
        <>
            <h1>Новые заявки</h1>

            <div className="grid grid-rows-1 grid-cols-6 lg:grid-cols-12 gap-4">
                <div className="col-span-6" id={"js-manager-editor"}>
                    {userEditor.status === 'disabled' && requestEditor.status === 'idle' ? (<SpeeddatingManagerUserSearch />) : null}
                    {userEditor.status !== 'disabled' && requestEditor.status !== 'idle' ? (<UserEditForm/>) : null}
                    {userEditor.status === 'disabled' && requestEditor.status !== 'idle' ? (<RequestEditorForm type="rec" event={0}/>) : null}
                    {userEditor.status === 'disabled' && requestEditor.status !== 'idle' ? (<EventHistoryCard/>) : null}
                </div>
                <div className="col-span-6">
                    <h2>Новые заявки {newRequests.state === "fetching" ? "(...обновление...)" : null}</h2>

                    {newRequests.state === "failed" ? (<>
                        Ошибка загрузки
                    </>) : null}

                    {newRequests.items.length > 0 ? (<>
                        {newRequests.items.map(a => a).sort(requestSortFunction).map(req => (<VisitorCardNewRequest key={req.id} req={req}/>))}
                    </>) : null}

                </div>
            </div>


        </>
    )
}
