import React from "react";

function Home() {
    return (
        <>
            <h1>Home</h1>
        </>
    )
}

export default Home;