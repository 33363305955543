import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserPreviewBig from '../components/UserPreviewBig';
import { Link } from 'react-router-dom';
import Error from '../components/error';
import { performExpoUserListFetch, selectExpoUserList } from '../reducers/expo';
import { getLoginState } from '../reducers/login';
import { useForm } from 'react-hook-form';

export default function AgencyExpoList () {
  const dispatch = useDispatch();

  const expoUserList = useSelector(selectExpoUserList);
  const loginState = useSelector(getLoginState);

  const onReload = () => {
    dispatch(performExpoUserListFetch());
  };

  useEffect(() => {
    onReload();
  }, []);

  // Form start
  const { register, getValues, watch, reset } = useForm<any>({});

  const [expoUserListItems, setExpoUserListItems] = useState([...expoUserList.items]);

  const filter = () => {
    setExpoUserListItems(expoUserList.items.filter((user) => {
      const { expo_id, name } = getValues();
      if (expo_id !== '') {
        if (user.expo_id !== Number(expo_id)) {
          return false;
        }
      }

      if (name !== '') {
        if (!user.display_name.toLowerCase().includes(name.toLowerCase())) {
          return false;
        }
      }

      return true;
    }));
  };

  const formWatch = watch();

  useEffect(() => {
    filter();
  }, [expoUserList.items, formWatch.expo_id, formWatch.name])
  // Form end

  if (!loginState.admin_privilege.agency) return (<>Вам сюда нельзя</>);

  return (
    <>
      <h1>Каталог брачного агентства</h1>

      <div>
        <form>
          <h2>Фильтр</h2>
          <div className={'grid lg:grid-cols-12 grid-cols-4 gap-2'}>
            <div className={'col-span-2'}>
              <label>ИД каталога</label>
              <input type={'text'} {...register('expo_id')}/>
            </div>

            <div className={'col-span-2'}>
              <label>Имя</label>
              <input type={'text'} {...register('name')}/>
            </div>
          </div>
        </form>
      </div>

      <div className="grid grid-rows-1 grid-cols-12 gap-4">
        <div className="col-start-1 col-end-13">
          <div className="w-container2">
            <h2 className={'mt-4 pb-0'}>Пользователи {expoUserList.state === 'fetching' ? '(...обновление...)' : null}</h2>

            {expoUserList.error !== '' ? (<>
              <Error error={expoUserList.error}/>
            </>) : null}

            {expoUserListItems.length > 0 ? expoUserListItems.map(user => (
              <UserPreviewBig key={user.id} user={user} agency={!!loginState.admin_privilege.agency} contacts={
                [{ name: 'ИД каталога', value: user.expo_id.toString() }]
              } buttons={<>
                <Link className={'btn mb-1 block text-center'} to={`/users/${user.id}/view`}>Профиль</Link>
                <Link className={'btn mb-1 block text-center'} to={`/agency/${user.id}/view`}>БА профиль</Link>
              </>}/>
            )) : null}
          </div>
        </div>
      </div>
    </>);
}
