import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectUserEditorList, selectUserEditorListQuery, userListQuerySet, performUserListQuery, userListReset} from '../reducers/userEditor'
import {UserListFilter} from "../types/spedddating";
import UserPreviewBig from "../components/UserPreviewBig";
import UserFilterForm from "../components/UserFilterForm";
import {Link, useParams} from "react-router-dom";
import {getLoginState} from "../reducers/login";
import Error from "../components/error";
import {performFetchManagerCallStats, selectManagerCallStats, selectManagerCallLogs, performFetchManagerCallLogs} from "../reducers/managerCallStats";
import moment from "moment";
import {ttCallStatus} from "../types/toText";


export default function ManagerCallLogs() {
    const dispatch = useDispatch();

    const {id: _sid} = useParams() as { id: string };
    const id = +_sid;

    console.log(_sid, id);

    const logs = useSelector(selectManagerCallLogs);
    const loginState = useSelector(getLoginState);

    useEffect(() => {
        // On reload: reset data
        dispatch(performFetchManagerCallLogs(id));
    }, []);

    if (!loginState.admin_privilege.km) return (<>Вам сюда нельзя</>);

    return (
        <>
            <h1>Логи звонков</h1>

            {logs.items.map(log => (
                <div key={log.id} className={"grid grid-cols-12 gap-2 hover:bg-gray-300"}>
                    <div className={"col-span-2"}>{moment(log.date).format("DD.MM.YYYY HH:mm")}</div>
                    <div className={"col-span-3"}>{log.user.display_name} (+{log.user.tel})</div>
                    <div className={"col-span-5"}>{ttCallStatus(log.status)} ({log.comment})</div>

                    <div className={"col-span-2"}>
                        <Link to={`/users/${log.user.id}/view`}>[профиль]</Link> &nbsp;
                        <Link to={`/manager/calls/${log.user.id}`}>[позвонить]</Link>
                    </div>

                </div>
            ))}

        </>)
}