import React, {useEffect} from "react"
import {BrowserRouter, Switch, Route, NavLink} from 'react-router-dom';
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Nav from './components/Nav'
import AppContainer from './components/AppContainer'
import {useDispatch, useSelector} from "react-redux";
import {getLoginState} from "./reducers/login";
import ManagerRequest from "./pages/ManagerRequest";
import ManagerEvent from "./pages/ManagerEvent";
import ManagerHistory from "./pages/ManagerHistory";
import ManagerCall from "./pages/ManagerCalls";
import ManagerCallsMake from "./pages/ManagerCallsMake";
import ManagerCallStats from "./pages/ManagerCallStats";
import ManagerCallLogs from "./pages/ManagerCallLogs";
import ManagerKV from "./pages/ManagerKV";
import Cafes from "./pages/Cafes";
import Events from "./pages/Events";
import UserList from "./pages/UserList";
import UserProfileView from "./pages/UserProfileView";
import UserProfileAgency from "./pages/UserProfileAgency";
import UserSearchAgency from "./pages/UserSearchAgency";
import AgencyClientList from "./pages/AgencyClientList";
import AgencyExpoAccess from "./pages/AgencyExpoAccess";
import AgencyExpoList from "./pages/AgencyExpoList";
import ExportProfile from "./pages/ExportProfile";
import Payments from "./pages/Payments";
import PaymentNew from "./pages/PaymentNew";
import {fetchManagerList, selectManagerList} from "./reducers/userEditor";

function App() {
    const loginState = useSelector(getLoginState);
    const managerList = useSelector(selectManagerList);
    const dispatch = useDispatch();

    const onReset = () => {
        dispatch({type: "RESET"})
    }

    useEffect(() => {
        if (managerList.status === 'idle') {
            dispatch(fetchManagerList());
        }
    }, [managerList]);

    return (
        <BrowserRouter>
            <Nav>
                {loginState.status === 'authenticated' ? (
                    <nav>
                        <NavLink exact to="/">Домой</NavLink>
                        {loginState.admin_privilege.km ? (<NavLink to="/manager/requests">Заявки</NavLink>) : null}
                        {loginState.admin_privilege.km ? (<NavLink to="/manager/events">Вечеринки</NavLink>) : null}
                        {loginState.admin_privilege.km ? (<NavLink to="/manager/history">История</NavLink>) : null}
                        {loginState.admin_privilege.km ? (<NavLink to="/manager/calls">Исходящий</NavLink>) : null}
                        {loginState.admin_privilege.kv ? (<NavLink to="/manager/kv">Ведущий</NavLink>) : null}
                        {loginState.admin_privilege.cafes ? (<NavLink to="/cafes">Ред.кафе</NavLink>) : null}
                        {loginState.admin_privilege.cafes ? (<NavLink to="/events">Ред.вечеринки</NavLink>) : null}
                        {loginState.admin_privilege.userEdit ? (<NavLink to="/users">Ред.пользователей</NavLink>) : null}
                        {loginState.admin_privilege.agency ? (<NavLink to="/agency">Брачное агентство</NavLink>) : null}
                        {/*{loginState.admin_privilege.agency ? (<NavLink to="/expo">Каталог БА</NavLink>) : null}*/}
                        {loginState.admin_privilege.userEdit ? (<NavLink to="/payments">Счета и оплаты</NavLink>) : null}
                        {loginState.admin_privilege.km ? (<NavLink to="/stats/calls">Статы</NavLink>) : null}
                        {loginState.status === 'authenticated' ? (<NavLink to="/logout">Выйти</NavLink>) : null}
                        <button onClick={onReset}>Reset!</button>
                    </nav>
                ) : (
                    <nav>
                        <NavLink to="/">Домой</NavLink>
                        <NavLink to="/login">Вход</NavLink>
                    </nav>
                )}
            </Nav>
            <AppContainer>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route path='/login' component={Login}/>
                    <Route path='/logout' component={Logout}/>
                    <Route path='/manager/requests' component={ManagerRequest}/>
                    <Route path='/manager/events' component={ManagerEvent}/>
                    <Route path='/manager/history' component={ManagerHistory}/>
                    <Route exact path="/manager/calls" component={ManagerCall}/>
                    <Route exact path="/manager/calls/page/:page" component={ManagerCall}/>
                    <Route path="/manager/calls/:id" component={ManagerCallsMake}/>
                    <Route exact path="/stats/calls" component={ManagerCallStats}/>
                    <Route exact path="/stats/calls/manager/:id" component={ManagerCallLogs}/>
                    <Route path="/manager/kv" component={ManagerKV}/>
                    <Route path='/cafes' component={Cafes}/>
                    <Route path='/events' component={Events}/>
                    <Route exact path='/users' component={UserList}/>
                    <Route exact path='/users/:id/view' component={UserProfileView}/>
                    <Route exact path='/users/:id/export' component={ExportProfile}/>
                    <Route exact path='/agency' component={AgencyClientList}/>
                    <Route exact path='/agency/:id/view' component={UserProfileAgency}/>
                    <Route exact path='/agency/:id/search' component={UserSearchAgency}/>
                    <Route exact path='/expo/access' component={AgencyExpoAccess}/>
                    <Route exact path='/expo/list' component={AgencyExpoList}/>
                    <Route exact path='/payments' component={Payments}/>
                    <Route exact path='/payments/new/:id' component={PaymentNew}/>
                </Switch>
            </AppContainer>
        </BrowserRouter>
    )
}

export default App;
