import React from "react";
import {SpeeddatingRequest, SpeeddatingRequestWithUser} from "../../types/spedddating";
import {useDispatch} from "react-redux";
import {requestEdit} from "../../reducers/spedddatingRequests";
import {PHOTOS_BASE_URL} from "../../app.config";
import {ttBirthAge} from "../../types/toText";
import _ManagerName from "../_ManagerName";


export default function VisitorCard(props: { req: SpeeddatingRequestWithUser }) {
    const req = props.req;

    const dispatch = useDispatch();

    const editRequest = () => {
        dispatch(requestEdit({id: req.id, data: req}));
        document.getElementById('js-manager-editor')?.scrollIntoView({behavior: "smooth"});
    }

    let dayStatus;
    if (req.status_evt === "waiting" && req.status_day === "new") dayStatus = {
        txt: "Ожидает подтверждения",
        col: "border-gray-400",
        text: "text-gray-400",
    };
    else if (req.status_evt === "waiting" && req.status_day === "na") dayStatus = {
        txt: "Не отвечает",
        col: "border-yellow-400",
        text: "text-yellow-400",
    };
    else if (req.status_evt === "waiting" && req.status_day === "confirmed") dayStatus = {
        txt: "Подтверждена",
        col: "border-green-400",
        text: "text-green-400",
    };
    else if (req.status_evt === "waiting" && req.status_day === "canceled") dayStatus = {
        txt: "Отменена",
        col: "border-red-400",
        text: "text-red-400",
    };
    else if (req.status_evt === "late") dayStatus = {
        txt: "Опаздывает",
        col: "border-yellow-400",
        text: "text-yellow-400",
    };
    else if (req.status_evt === "confirmed") dayStatus = {
        txt: "На вечеринке",
        text: "text-green-600",
        col: "border-green-600"
    };
    else dayStatus = {
            txt: `<d:${req.status_day};e:${req.status_evt}>`,
            col: "border-black-400",
            text: "text-black-400",
        };


    return (
        <div
            className={`w-container border-l-8 ${dayStatus.col}`}
            onClick={editRequest}>

            <div className={"flex flex-col lg:flex-row"}>
                <div className={"mr-2"}>
                    {req.user?.photos?.length ? (
                        <img className={"w-24 h-24"}
                             src={`${PHOTOS_BASE_URL}z${req.user.photos[0].dir}/${req.user.photos[0].thumb}`}
                             alt={""}/>
                    ) : (
                        <div className={"square bg-gray-400 w-24"}/>
                    )}
                </div>
                <div>
                    <div><span className="font-bold">{req.name} {req.name !== req.user?.display_name ? req.user?.display_name : null} </span></div>
                    <div><a className={"link"} href={`tel:+${req.tel}`}>+{req.tel}</a></div>
                    <div>{req.age ? `Возраст (заявка): ${req.age}` : ttBirthAge(req.user?.birth || null, req.user?.age || null, req.user?.birth_year || null)}</div>
                    <div><span className="italic text-gray-500">Статус:</span> {dayStatus.txt} {req.code ? `(${req.code})` : null}</div>
                </div>
            </div>

            <div><span className="italic text-gray-500">Оплатить: </span>{req.price - req.paid_online - req.paid_cash} из {req.price}</div>
            {req.comment_km ? (<div><span className="italic text-gray-500">К.М.:</span> {req.comment_km} </div>) : null}
            {req.comment_kv ? (<div><span className="italic text-gray-500">К.В.:</span> {req.comment_kv} </div>) : null}
            <div><span className="italic text-gray-500">Менеджер:</span><_ManagerName mgr_id={req.manager_id}/></div>
        </div>
    )
}
