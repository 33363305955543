import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createInvoice, selectInvoiceCreator, createInvoiceReset} from "../reducers/payments";
import Error from "../components/error";
import {useParams} from "react-router-dom";
import {performUserGetById, selectUserEditorProfile} from "../reducers/userEditor";
import {useForm} from "react-hook-form";
import {InvoiceEditable} from "../types/spedddating";

export default function PaymentNew() {
    const dispatch = useDispatch();

    const userEditorProfile = useSelector(selectUserEditorProfile);
    const invoiceCreator = useSelector(selectInvoiceCreator);

    const {id} = useParams() as { id: string };

    if (userEditorProfile.data?.id !== +id) {
        dispatch(performUserGetById({id: +id}));
    }

    // FORM START
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<InvoiceEditable>();

    const onNewInvoiceSubmit = (data: InvoiceEditable) => {
        console.log(data);
        dispatch(createInvoice({
            user_id: +data.user_id,
            amount: +data.amount,
            description: data.description,
        }))
    }

    // FORM END

    useEffect(() => {
        if (userEditorProfile && userEditorProfile.data && userEditorProfile.data.id) {
            setValue("user_id", "" + userEditorProfile.data.id);
        }
    }, [userEditorProfile.data]);


    useEffect(() => {
        if(invoiceCreator.status === "complete") {
            dispatch(createInvoiceReset());
            document.location.href = "/payments";
        }
    }, [invoiceCreator.status]);

    return (<>
        <h1>Выставление счета</h1>

        <form className={"grid grid-cols-12 gap-2"} onSubmit={handleSubmit(onNewInvoiceSubmit)}>
            <div className={"col-span-6"}>
                <label>Имя пользователя</label>
                <input type={"text"} readOnly={true} value={userEditorProfile.data?.display_name}/>
            </div>
            <div className={"col-span-6"}>
                <label>Телефон</label>
                <input type={"text"} readOnly={true} value={"+" + userEditorProfile.data?.tel}/>
            </div>

            <div className={"col-span-12"}>
                <label>Сумма платежа</label>
                <input type={"text"} className={errors.amount ? "error" : ""} {...register("amount", {
                    // pattern: /^\d+(|\.\d{2})$/,
                    pattern: /^\d+$/,
                    required: true,
                })} placeholder={"Например: 499"}/>
            </div>

            <div className={"col-span-12"}>
                <label>Назначение платежа</label>
                <input type={"text"} className={errors.description ? "error" : ""} {...register("description", {
                    required: true
                })} placeholder={"Например: Здарова, отец, бабло гони"}/>
            </div>

            <input type={"hidden"} {...register("user_id")}/>

            {invoiceCreator.error ? <Error error={invoiceCreator.error} /> : null}

            <div className={"col-span-12 text-right"}>
                <button className={"btn"} type={"submit"} disabled={invoiceCreator.status === 'pending'}>Создать</button>
            </div>

        </form>

    </>);
}