export default function nest(src: object): object {
    const _nest = (obj: any, prevKey: string) => {
        const ret = {} as Record<string, any>;
        Object.entries(obj).forEach(([key, val]) => {
            if (typeof val === "object" && val !== null) {
                const tmpObject = _nest(val, key);
                Object.entries(tmpObject).forEach(([tKey, tVal]) => {
                    ret[`${prevKey ? prevKey + "." : ""}${tKey}`] = tVal;
                });
            } else {
                ret[`${prevKey ? prevKey + "." : ""}${key}`] = val;
            }
        });
        return ret;
    }

    return _nest(src, '');
}