import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PHOTOS_BASE_URL} from "../app.config"

import {
    fetchSpeeddatingCafes,
    performSpeeddatingCafeUpdate,
    cafeEdit,
    cafeEditReset,
    selectCafes,
    selectCafeEditor, performSpeeddatingCafeAddPhoto, performSpeeddatingCafeDeletePhoto
} from '../reducers/cafes'
import {useForm} from "react-hook-form";
import {SpeeddatingCafe, SpeeddatingRequest} from "../types/spedddating";
import Error from "../components/error";
import {Link} from "react-router-dom";
import {getLoginState} from "../reducers/login";

export default function Cafes() {
    const dispatch = useDispatch();

    const loginState = useSelector(getLoginState);
    const cafes = useSelector(selectCafes);
    const cafeEditor = useSelector(selectCafeEditor);

    if (cafes.status === 'idle') {
        dispatch(fetchSpeeddatingCafes());
    }

    // ================================ <CAFES FORM> ================================

    const {register, handleSubmit, setValue} = useForm<SpeeddatingCafe>();

    useEffect(() => {
        // Update form from redux store
        if (cafeEditor.data && (cafeEditor.status === 'editing' || cafeEditor.status === 'disabled')) {
            // @ts-ignore
            (Object.keys(cafeEditor.data) as Array<keyof SpeeddatingRequest>).forEach(key => setValue(key, cafeEditor.data[key]));
        }
    }, [cafeEditor, setValue])

    const onCafeSubmit = (data: SpeeddatingCafe) => {
        dispatch(performSpeeddatingCafeUpdate({id: cafeEditor.id, data: data}))
    }
    const onCafeReset = () => {
        dispatch(cafeEditReset())
    }

    const onEditCafe = (id: number, data: SpeeddatingCafe) => {
        dispatch(cafeEdit({id, data}))
    }

    // ================================ CAFE PHOTOS ================================

    const [photo, setPhoto] = useState({blob: "", file: null, h: 0, w: 0});

    const onPreviewPhoto = (e: any) => {
        setPhoto(() => {
            return {file: e.target.files[0], blob: URL.createObjectURL(e.target.files[0]), h: 0, w: 0}
        });
    }
    const onPreviewPhotoLoad = (e: any) => {
        setPhoto(state => {
            return {...state, w: e.target.naturalWidth, h: e.target.naturalHeight};
        });
    }
    const onPhotoFormSubmit = (e: any) => {
        e.preventDefault();
        dispatch(performSpeeddatingCafeAddPhoto({cafeId: cafeEditor.id, photo: photo.file}));
    }
    const onPhotoFormReset = () => {
        setPhoto(() => {
            return {file: null, blob: "", h: 0, w: 0}
        });
    }
    const onPhotoDelete = (id: number) => {
        dispatch(performSpeeddatingCafeDeletePhoto({cafeId: cafeEditor.id, photoId: id}));
    }

    // ================================ </CAFES FORM> ===============================

    const [cafeFilter, cafeFilterSet] = useState("");

    // ================================ </CAFES FORM> ===============================

    if(!loginState.admin_privilege.cafes) return (<>Вам сюда нельзя</>);

    return (
        <>
            <h1>Редактор кафе</h1>

            <div className="grid grid-rows-1 grid-cols-12 gap-4">
                <div className="col-start-1 col-end-6">
                    <div className="w-container">
                        {cafeEditor.id === 0 ? (
                            <h2>Создание кафе</h2>
                        ) : (
                            <h2>Редактирование кафе</h2>
                        )}

                        <form onSubmit={handleSubmit(onCafeSubmit)} onReset={() => onCafeReset()}>
                            <div className="grid grid-cols-1 gap-2">
                                <div className="col-span-6">
                                    <label htmlFor="req_ed_email">Название кафе</label>
                                    <input id="req_ed_email" type="text" {...register("name", {required: true})}/>
                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="req_ed_email">Ближайшее метро</label>
                                    <input id="req_ed_email" type="text" {...register("metro")}/>
                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="req_ed_email">Адрес</label>
                                    <input id="req_ed_email" type="text" {...register("address", {required: true})}/>
                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="req_ed_email">Карта</label>
                                    <input id="req_ed_email" type="text" {...register("map")}/>
                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="req_ed_email">Описание</label>
                                    <textarea id="req_ed_email" className="h-64" {...register("description", {required: true})}/>
                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="req_ed_email">Как пройти</label>
                                    <textarea id="req_ed_email" className="h-64" {...register("how_to_pass", {required: true})}/>
                                </div>
                            </div>

                            <div className="px-4 py-3 text-right">
                                <input className="btn white" type="reset"
                                       value={cafeEditor.id === 0 ? "Сбросить" : "Отменить редактирование"}/>
                                <input className="btn" type="submit" disabled={cafeEditor.status === 'committing'}
                                       value={cafeEditor.id === 0 ? "Создать" : "Сохранить"}/>
                            </div>
                        </form>

                        {cafeEditor.id === 0 ?
                            <div className="italic">*: Фотки для нового кафе можно будет задать после его
                                создания</div> : null}
                    </div>

                    {cafeEditor.id === 0 ? null : <div className="w-container">
                        <h2>Фотки</h2>

                        <div className="flex overflow-hidden my-4 border shadow rounded-md">
                            <div className="w-52 h-52 bg-gray-200">
                                {photo.blob !== "" ? <img src={photo.blob} alt="" onLoad={onPreviewPhotoLoad}/> : null}
                            </div>

                            <div className="self-center pl-4">
                                <form onSubmit={onPhotoFormSubmit} onReset={onPhotoFormReset}>
                                    <input type="file" onChange={onPreviewPhoto}/>
                                    <div className="italic">Превью может быть вписано в квадрат не точно!</div>
                                    <div>Оригинальный размер: <span className="font-bold">{photo.h}x{photo.w}</span></div>

                                    <button className="btn white" type="reset">Сбросить</button>
                                    <button className="btn" type="submit">Загрузить</button>
                                </form>
                            </div>
                        </div>

                        {cafeEditor.data.photos ? cafeEditor.data.photos.map(photo => (
                            <div key={photo.id} className="flex overflow-hidden my-4 border shadow rounded-md">
                                <div className="w-52 h-52 bg-gray-200">
                                    <Link to={{pathname: `${PHOTOS_BASE_URL}z${photo.dir}/${photo.big}`}} target={"_blank"}>
                                        <img src={`${PHOTOS_BASE_URL}z${photo.dir}/${photo.thumb}`} alt={photo.thumb} className="h-full w-full"/>
                                    </Link>
                                </div>

                                <div className="self-center pl-4">
                                    <div>
                                        <button className="btn" onClick={() => {
                                            onPhotoDelete(photo.id)
                                        }}>Удалить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )) : null}
                    </div>}
                </div>

                <div className="col-start-6 col-end-13">

                    {cafes.status === "fetching" ? (<>
                        Идет загрузка...
                    </>) : null}

                    {cafes.status === "failed" ? (<>
                        <Error error={cafes.error}/>
                    </>) : null}

                    {cafes.status === "complete" ? (<div>

                        <div className={"mt-4"}>
                            <label>Фильтр</label>
                            <input type={"text"} value={cafeFilter} onChange={e => cafeFilterSet(e.target.value)}/>
                        </div>
                        <div>
                            Отображено {cafes.items.filter(cafe => (cafeFilter === "" || cafe.name.toLowerCase().indexOf(cafeFilter.toLowerCase()) !== -1)).length} из {cafes.items.length}
                        </div>

                        {cafes.items.filter(cafe => (cafeFilter === "" || cafe.name.toLowerCase().indexOf(cafeFilter.toLowerCase()) !== -1)).map((cafe) => (
                            <div key={cafe.id} className="w-container cursor-pointer" onClick={() => onEditCafe(cafe.id, cafe)}>
                                <div className={"grid grid-cols-12 gap-2"}>
                                    <div className={"col-span-2"}>
                                        {cafe.photos && cafe.photos.length ? (<Link to={{pathname: `${PHOTOS_BASE_URL}z${cafe.photos[0].dir}/${cafe.photos[0].big}`}} target={"_blank"}>
                                            <img className={"w-full"}
                                                 src={`${PHOTOS_BASE_URL}z${cafe.photos[0].dir}/${cafe.photos[0].thumb}`}
                                                 alt={""}/>
                                        </Link>) : (<>
                                            <div className={"square bg-gray-400"}/>
                                        </>)}
                                    </div>
                                    <div className={"col-span-10"}>
                                        <div><span className={"font-bold"}>{cafe.name}</span></div>
                                        <div>м.{cafe.metro} - {cafe.address}</div>
                                    </div>
                                </div>
                            </div>))}
                    </div>) : null}
                </div>

            </div>
        </>)
}