import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../store";
import axios from "axios";
import {BASE_URL} from "../app.config"
import {ApiError, User} from "../types/spedddating";


const apiErrorHandler = (error: any) => {
    if (error.response && error.response.data) {
        if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
            throw new ApiError(error.response.data);
        }
    }

    throw error;
}


interface UserReduxInitState {
    userSearchResult: {
        state: 'idle' | 'fetching' | 'complete' | 'failed',
        items: Array<User>,
        error: string
    },
    userEditor: {
        status: 'disabled' | 'editing' | 'committing' | 'failed',
        id: number,
        data: User | null,
        error: string
    },
}

const initialState: UserReduxInitState = {
    userSearchResult: {
        state: 'idle',
        items: [],
        error: ""
    },
    userEditor: {
        status: "disabled",
        id: 0,
        data: null,
        error: ""
    },
};

export const performUserProfileEdit = createAsyncThunk(
    'user/profile/edit',
    async (r: { id: number, data: User }) => {
        const result = await axios(`${BASE_URL}/user/profile/${r.id}`, {
            method: 'put',
            withCredentials: true,
            data: {
                display_name: r.data.display_name,
                gender: r.data.gender,
                tel: r.data.tel,
                email: r.data.email ? r.data.email : null,
                birth_year: r.data.birth_year,
                birth: r.data.birth ? r.data.birth : null,
            }
        }).catch(apiErrorHandler);

        return result.data;
    }
);

export const performUserSearch = createAsyncThunk(
    'user/search/for-mgr',
    async (query: { tel: string }) => {
        const result = await axios(`${BASE_URL}/user/search?filter[tel]=${query.tel}`, {
            method: 'get',
            withCredentials: true,
        }).catch(apiErrorHandler);

        return result.data;
    }
);

export const counterSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userEdit: ((state, action: PayloadAction<{id: number, data: User}>) => {
            state.userEditor = {
                status: "editing",
                id: action.payload.id,
                data: action.payload.data,
                error: ''
            }
        }),
        userEditReset: (state) => {
            state.userEditor = {
                status: "disabled",
                id: 0,
                data: null,
                error: ''
            }
        },

        resetUserSearchResults: (state) => {
            state.userSearchResult.state = "idle";
            state.userSearchResult.items = [];
            state.userSearchResult.error = "";
        },
    },
    extraReducers: builder => {
        builder.addCase(performUserSearch.pending, state => {
            // console.log(Date.now(), "performUserSearch: pending");
            state.userSearchResult.state = 'fetching';
        });
        builder.addCase(performUserSearch.fulfilled, (state, action) => {
            // console.log(Date.now(), "performUserSearch: fulfilled");
            if(state.userSearchResult.state !== "fetching") return;
            state.userSearchResult.state = 'complete';
            state.userSearchResult.items = action.payload.response;
        });
        builder.addCase(performUserSearch.rejected, (state, action) => {
            if(state.userSearchResult.state !== "fetching") return;
            state.userSearchResult.state = 'failed';
            state.userSearchResult.error = JSON.stringify(action.error, null, 2);
        });

        builder.addCase(performUserProfileEdit.fulfilled, (state) => {
            state.userEditor.status = 'disabled';
            state.userEditor.id = 0;
            state.userEditor.data = null;
            state.userEditor.error = "";
        });
        builder.addCase(performUserProfileEdit.rejected, (state, action) => {
            state.userSearchResult.state = 'failed';
            state.userSearchResult.error = JSON.stringify(action.error, null, 2);
        });
    }
});

export const selectSpeeddatingRequestUserEditor = (state: RootState) => state.users.userEditor;
export const selectUserSearchResult = (state: RootState) => state.users.userSearchResult;

export const {userEdit, userEditReset, resetUserSearchResults} = counterSlice.actions;

export default counterSlice.reducer;