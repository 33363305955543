import LoginForm from "../components/LoginForm";
import React from "react";

function Login() {
    return(
        <>
            <h1>Login</h1>
            <LoginForm></LoginForm>
        </>
    )
}

export default Login;