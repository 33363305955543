import React, {useEffect} from "react";
import {User} from "../../types/spedddating";
import {
    performUserProfileEdit,
    selectSpeeddatingRequestUserEditor,
    userEditReset
} from "../../reducers/users";
import {useDispatch, useSelector} from "react-redux";
import {useForm, SubmitHandler} from "react-hook-form"


export default function UserEditForm() {
    const dispatch = useDispatch();

    const userEditor = useSelector(selectSpeeddatingRequestUserEditor);

    const {register, handleSubmit, reset, formState: {errors}} = useForm<User>();

    useEffect(() => {
        // Update form from redux store
        if ((userEditor.status === 'editing') && (userEditor.data !== null)) {
            reset(JSON.parse(JSON.stringify(userEditor.data)));
        }
    }, [userEditor, reset]);

    const onUserEditSubmit: SubmitHandler<User> = (data) => {
        if (['editing', 'failed'].indexOf(userEditor.status) >= 0) {
            dispatch(performUserProfileEdit({id: userEditor.id, data: data}));
        }
    }

    const onUserEditReset = (e: any) => {
        e.preventDefault();
        dispatch(userEditReset());
    }

    return userEditor.status === 'disabled' ? null : (
        <div className="w-container">
            <h2>Редактирование пользователя</h2>
            <div className="py-4"><span className="font-bold">{userEditor.data?.display_name}</span> (+{userEditor.data?.tel})</div>

            <form onSubmit={handleSubmit(onUserEditSubmit)}>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-9">
                        <label htmlFor="req_ed_name">Имя</label>
                        <input id="req_ed_name" type="text" className={errors.display_name ? "error" : ""} {...register("display_name", {required: true})} />
                    </div>

                    <div className="col-span-3">
                        <div className="col-span-12">
                            <label htmlFor="gender">Пол</label>
                            <select id="gender"
                                    className={errors.gender ? "error" : ""}
                                    {...register("gender", {
                                        validate: (val) => val !== 'u'
                                    })}>
                                <option value="u">Не задан</option>
                                <option value="m">М</option>
                                <option value="f">Ж</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-span-12">
                        <label htmlFor="req_ed_tel">Телефон</label>
                        <input id="req_ed_tel" type="text" className={errors.tel ? "error" : ""} {...register("tel", {required: true})}/>
                    </div>

                    <div className="col-span-12">
                        <label htmlFor="req_ed_email">E-mail</label>
                        <input id="req_ed_email" type="text" className={errors.email ? "error" : ""} {...register("email")}/>
                    </div>

                    <div className="col-span-12">
                        <label htmlFor="req_ed_email">Год рождения</label>
                        <input id="req_ed_email" type="text" className={errors.birth_year ? "error" : ""} {...register("birth_year", {required: true})}/>
                    </div>
                </div>
                <div className="px-4 py-3 text-right">
                    <button className="btn white" type="button" onClick={onUserEditReset}>Сбросить</button>
                    <button className="btn" type="submit" disabled={userEditor.status === 'committing'}>Сохранить</button>
                </div>
            </form>
        </div>
    )
}


