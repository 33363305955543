import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import moment from "moment";
import {CallAnswer, OnlyStringsRecursive} from "../types/spedddating";
import {PHOTOS_BASE_URL} from "../app.config";
import {fetchSpeeddatingPublicEvents, selectSpeeddatingPublicEvents} from "../reducers/spedddatingEvents";
import {selectUserCall, setOutCall, makeCallCancel, fetchSpeeddatingUserCall} from '../reducers/calls'
import {ttBirthAge} from "../types/toText";
import Error from "../components/error";
import {selectManagerList} from "../reducers/userEditor";
import _ManagerName from "../components/_ManagerName";


interface LocationStateType {
    prev: {
        pathname: string;
        search: string;
    }
}

export default function ManagerCallMake() {
    const dispatch = useDispatch();

    const {id} = useParams() as { id: string };

    const userCall = useSelector(selectUserCall);
    const events = useSelector(selectSpeeddatingPublicEvents);
    const managerList = useSelector(selectManagerList);

    const history = useHistory<LocationStateType>();

    if (events.state === 'unknown') {
        dispatch(fetchSpeeddatingPublicEvents());
    }

    // On load
    useEffect(() => {
        dispatch(fetchSpeeddatingUserCall({id: +id}));
    }, [])

    // ================================ <CAFES FORM> ================================

    const {register: registerAnswer, watch: watchAnswer, reset: resetAnswer, setValue: setValueAnswer, clearErrors: clearErrorsAnswer, setError: setErrorAnswer, formState: {errors: errorsAnswer}} = useForm<OnlyStringsRecursive<CallAnswer>>();
    const answer = watchAnswer();

    const callStatusToReadable = (status: "phone_off" | "na" | "denial" | "agreement"): string => {
        if (status === "phone_off") return "Недоступен";
        else if (status === "na") return "Не отвечает";
        else if (status === "denial") return "Ответил (отказ)";
        else if (status === "agreement") return "Ответил (записан)";
        return "Неизвестно";
    }
    const getVisitStatus = (status_day: string, status_evt: string): string => {
        if (status_evt === 'confirmed') return "Пришел";
        else if (status_day === 'confirmed') return "Прогулял";
        else return "Отменил";
    }

    // ================================ </CAFES FORM> ===============================

    const onBackAction = () => {
        if (history.location?.state?.prev) {
            history.push(history.location.state.prev.pathname + history.location.state.prev.search + `#call-user-${id}`);
        } else {
            history.push("/manager/calls");
        }
    }

    const onCancelCall = () => {
        dispatch(makeCallCancel());
        onBackAction();
    }

    // Redirect on call done
    useEffect(() => {
        if (userCall.status === 'data-saved') {
            dispatch(makeCallCancel());
            onBackAction();
        }
    }, [userCall.status])


    // Set event price
    useEffect(() => {
        console.log("event updated");
        const newEvent = events.by_id[answer.speeddating_event_id];
        if(newEvent) {
            setValueAnswer("price", (userCall.data?.gender === 'f' ? newEvent.price_f : newEvent.price_m).toString())
        }
    }, [answer.speeddating_event_id])

    const onCallAction = (action: "phone_off" | "na" | "denial" | "agreement") => {
        clearErrorsAnswer();
        if (!userCall.data) return;

        if (action === "phone_off") {
            dispatch(setOutCall({
                id: userCall.data.id, data: {
                    status: "phone_off",
                    call_comment: answer.call_comment,
                    manager_comment: answer.manager_comment,
                    speeddating_event_id: 0,
                    price: 0,
                }
            }));
        } else if (action === "na") {
            dispatch(setOutCall({
                id: userCall.data.id, data: {
                    status: "na",
                    call_comment: answer.call_comment,
                    manager_comment: answer.manager_comment,
                    speeddating_event_id: 0,
                    price: 0,
                }
            }))
        } else if (action === "denial") {
            if (!answer.call_comment || answer.call_comment === '') return setErrorAnswer("call_comment", {type: "manual"});
            dispatch(setOutCall({
                id: userCall.data.id, data: {
                    status: "denial",
                    call_comment: answer.call_comment,
                    manager_comment: answer.manager_comment,
                    speeddating_event_id: 0,
                    price: 0,
                }
            }));
        } else if (action === "agreement") {
            if (!answer.speeddating_event_id || answer.speeddating_event_id === "0") return setErrorAnswer("speeddating_event_id", {type: "manual"});
            if (!answer.price || answer.price === "0") return setErrorAnswer("price", {type: "manual"});
            dispatch(setOutCall({
                id: userCall.data.id, data: {
                    status: "agreement",
                    call_comment: answer.call_comment,
                    manager_comment: answer.manager_comment,
                    speeddating_event_id: +answer.speeddating_event_id,
                    price: +answer.price
                }
            }))
        }
    }

    // ================================ </CAFES FORM> ===============================

    return (
        <>
            <h1>Исходящий звонок</h1>

            <div className="grid grid-rows-1 grid-cols-12 gap-4">
                <div className="col-start-1 col-end-13">
                    {userCall.status !== "idle" && userCall.data ? (<>
                        <div className="w-container">
                            <h2>Исходящий звонок</h2>

                            <div className="grid grid-cols-12 grid-row-1">
                                <div className="col-span-4 text-center">
                                    <div className="w-2/4 mx-auto">
                                        {userCall.data.photos && userCall.data.photos.length > 0 ? (
                                            <img src={`${PHOTOS_BASE_URL}/z${userCall.data.photos[0].dir}/${userCall.data.photos[0].thumb}`} alt=""/>
                                        ) : (
                                            <div className={"square bg-gray-400"}/>
                                        )}
                                    </div>
                                    <div className={"text-2xl"}>{userCall.data.display_name}</div>
                                    <div className={"text-xs text-gray-500"}>{ttBirthAge(userCall.data.birth, userCall.data.age, userCall.data.birth_year)}</div>
                                    <div><a className={"link"} href={`tel:+${userCall.data.tel}`}>+{userCall.data.tel}</a></div>
                                </div>
                                <div className="col-span-8">
                                    <div className="calling-step-1">
                                        <form>
                                            <div className="grid grid-cols-6 gap-2">

                                                <div className="col-span-4">
                                                    <label htmlFor="call_comment">Комментарий к звонку</label>
                                                    <input id="call_comment" type="text"
                                                           className={errorsAnswer.call_comment ? "error" : ""} {...registerAnswer('call_comment')}/>
                                                </div>

                                                <div className="col-span-4">
                                                    <label htmlFor="manager_comment">Комментарий для менеджера</label>
                                                    <input id="manager_comment" type="text"
                                                           className={errorsAnswer.manager_comment ? "error" : ""} {...registerAnswer('manager_comment')}/>
                                                </div>

                                                <div className="col-span-4">
                                                    <label htmlFor="speeddating_event_id">Вечеринка</label>
                                                    <select id="speeddating_event_id"
                                                            className={errorsAnswer.speeddating_event_id ? "error" : ""} {...registerAnswer('speeddating_event_id')}>
                                                        <option value={"0"}>Выберите вечеринку</option>
                                                        {events.items.map(event => (
                                                            <option key={event.id}
                                                                    value={event.id.toString()}>{moment(event.date).locale('ru').format("ddd DD.MM HH:mm")} {event.speeddating_cafe?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-span-4">
                                                    <label htmlFor="price">Цена</label>
                                                    <input id="price" type="text"
                                                           className={errorsAnswer.price ? "error" : ""} {...registerAnswer('price')}/>
                                                </div>


                                            </div>

                                        </form>
                                    </div>
                                    <div className="cpx-4 py-3">
                                        <button className="btn" disabled={userCall.status === "data-saving"}
                                                onClick={() => onCallAction("phone_off")}>Недоступен
                                        </button>
                                        <button className="btn yellow" disabled={userCall.status === "data-saving"}
                                                onClick={() => onCallAction("na")}>Не отвечает
                                        </button>
                                        <button className="btn green" disabled={userCall.status === "data-saving"}
                                                onClick={() => onCallAction("agreement")}>Запись
                                        </button>
                                        <button className="btn red" disabled={userCall.status === "data-saving"}
                                                onClick={() => onCallAction("denial")}>Отказ
                                        </button>
                                        <button className="btn white" onClick={() => onCancelCall()}>Отменить звонок
                                        </button>
                                    </div>

                                    {userCall.error ? (<>
                                        <Error error={userCall.error}/>
                                    </>) : null}

                                </div>
                            </div>
                        </div>

                        <div className="w-container">
                            <div className={"grid grid-cols-12 gap-2"}>
                                <div className={"col-span-6"}>
                                    <h2>История звонков</h2>
                                    {userCall.data.speeddating_calls?.map((call) => (<div key={call.id}>
                                        <span>{moment(call.date).format("DD.MM.YYYY HH:mm")} - </span>
                                        <span>[<_ManagerName mgr_id={call.manager_id}/>] - </span>
                                        <span>{callStatusToReadable(call.status)}{call.comment ? `: ${call.comment}` : ''}</span>
                                    </div>))}
                                </div>

                                <div className={"col-span-6"}>
                                    <h2>История визитов</h2>
                                    {userCall.data.speeddating_requests?.map((request) => (<div key={request.id}>
                                        <span>{moment(request.speeddating_event?.date).format("DD.MM.YYYY HH:mm")} - </span>
                                        <span>{getVisitStatus(request.status_day, request.status_evt)} - </span>
                                        <span>{request.speeddating_event?.speeddating_cafe?.name} </span>
                                        <span>({request.price} р.-)</span>
                                    </div>))}
                                </div>

                            </div>
                        </div>
                    </>) : null}

                </div>

            </div>
        </>)
}
