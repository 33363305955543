import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectUserEditorList, selectUserEditorListQuery, userListQuerySet, performUserListQuery, userListReset} from '../reducers/userEditor'
import {UserListFilter} from "../types/spedddating";
import UserPreviewBig from "../components/UserPreviewBig";
import UserFilterForm from "../components/UserFilterForm";
import {Link} from "react-router-dom";
import {getLoginState} from "../reducers/login";
import Error from "../components/error";


export default function UserEditor() {
    const dispatch = useDispatch();

    const userListQuery = useSelector(selectUserEditorListQuery);
    const userList = useSelector(selectUserEditorList);
    const loginState = useSelector(getLoginState);

    const onReload = (filter: UserListFilter, page: number) => {
        let offset = Math.ceil(page * userListQuery.limit);
        dispatch(userListQuerySet({filter, offset: offset, order: "id"}));
        dispatch(performUserListQuery());
    }

    useEffect(() => {
        // On reload: reset data
        dispatch(userListReset());
    }, []);

    return (
        <>
            <h1>Пользователи</h1>
            <UserFilterForm onUpdate={onReload} mode={"global"}>
                <div className="grid grid-rows-1 grid-cols-12 gap-4">
                    <div className="col-start-1 col-end-13">
                        <div className="w-container2">
                            <h2 className={"mt-4 pb-0"}>Пользователи {userList.status === "fetching" ? "(...обновление...)" : null}</h2>

                            {userList.error !== "" ? (<>
                                <Error error={userList.error}/>
                            </>) : null}

                            {userList.items.length > 0 ? userList.items.map(user => (
                                <UserPreviewBig key={user.id} user={user} agency={!!loginState.admin_privilege.agency} buttons={<>
                                    <Link className={"btn mb-1 block text-center"} to={`/users/${user.id}/view`}>Профиль</Link>
                                    <Link className={"btn mb-1 block text-center"} to={`/agency/${user.id}/view`}>БА профиль</Link>
                                    <Link className={"btn mb-1 block text-center"} to={`/payments/new/${user.id}`}>Выставить счет</Link>
                                </>}/>
                            )) : null}
                        </div>
                    </div>
                </div>
            </UserFilterForm>
        </>)
}