import moment from "moment";

export const ttAgencyStatus = (status: string) => {
    const translations: Record<string, string> = {
        'none': "Отсутствует",
        'request': "Заявка",
        'contract': "Договор активен",
        'finished': "Договор завершен",
        'cancelled': "Отменено",
        'expo_free': "Бесплатное размещение",
        'expo_paid': "Платное размещение",
    }
    if (status in translations) return translations[status];
    return "Неизвестен";
}

export const ttAgencyCandidateStatus = (status: string) => {
    const translations: Record<string, string> = {
        'none': "Нет",
        'candidate': "Кандидат",
        'not_interested': "Не интересно",
        'ready': "Готов к знакомству",
        'phones': "Созвон",
        'planning': "Планируется встреча",
        'meeting': "Встреча",
        'dislike': "Не устроило"
    }
    if (status in translations) return translations[status];
    return "Неизвестен";
}

export const ttBirthAge = (birth: string | Date | null, age: number | null, birth_year?: number | null) => {
    if (birth && birth !== '0000-00-00')
        return moment(birth).format("DD.MM.YYYY") + " (" + (moment().diff(moment(birth), 'years')) + ")";
    if (birth_year)
        return `Возраст: ${(new Date()).getFullYear() - birth_year}`;
    if (age)
        return `Возраст: ${age}`;
    return `Возраст неизвестен`;
}


export const ttCallStatus = (status: string) => {
    const _statuses = {
        phone_off: "Выключен",
        na: "Не отвечает",
        denial: "Отказ",
        agreement: "Записан",
    }

    if(status in _statuses) return _statuses[status as keyof typeof _statuses];
    return `?<${status}>`;
}
