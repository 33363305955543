
import React, {ReactElement, useState} from "react";
import {Control, Controller, UseFormRegisterReturn} from "react-hook-form";
import DatePicker from "react-datepicker";


export function EditableDate(props: {
    containerClass: string,
    isDirty: boolean,
    displayValue: any,
    value: any,
    control: Control<any>,
    name: string
}) {
    const [state, setState] = useState<'idle' | 'edit' | 'touched'>('idle');
    const setEdit = (e: any) => {
        setState('edit');
    }

    return (<>
        {state === 'idle' ? (
            <div className={props.containerClass + " " + (props.isDirty ? "bg-yellow-200" : "")} onClick={setEdit}>
                {props.displayValue}
            </div>
        ) : null}
        {state === 'edit' ? (<div className={props.containerClass}>
            <Controller
                control={props.control}
                name={props.name}
                render={({ field }) => (
                    <DatePicker
                        placeholderText='Select date'
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                    />
                )}
            />
        </div>) : null}
    </>);
}

export default function EditableField(props: {
    containerClass: string,
    isDirty: boolean,
    input: 'text' | 'textarea' | 'select' ,
    value: any,
    register: UseFormRegisterReturn,
    options?: Array<Array<string>>,
    inputPlaceholder?: string
}) {


    const [state, setState] = useState<'idle' | 'edit' | 'touched'>('idle');

    const setEdit = (e: any) => {
        setState('edit');
    }

    let value: string = "", readableValue: ReactElement = (<>undefined</>);
    if (props.value === null || props.value === undefined) value = "";
    else if (typeof props.value === "boolean") value = props.value ? "1" : "0";
    else if (typeof props.value === "number") value = props.value.toString();
    else if (typeof props.value === "string") value = props.value;

    if (value === "") {
        readableValue = (<span className={"text-gray-500"}>&lt;не задано&gt;</span>);
    } else if (props.input === "select") {
        if (props.options === undefined) {
            return (<div className={props.containerClass + " " + "bg-red-400"}>ERROR</div>);
        }
        readableValue = (<>{props.options.reduce((acc, [key, val]) => key === value ? val : acc, "undefined")}</>);

        // readableValue = value in props.options ? props.options[value] : "none";
    } else if (props.input === "textarea") {
        value = props.value;
        readableValue = (<>{value.split("\n").map(val => (<p>{val}</p>))}</>);
    } else {
        value = props.value;
        readableValue = (<>{props.value}</>);
    }

    return (<>
        {state === 'idle' ? (
            <div className={props.containerClass + " " + (props.isDirty ? "bg-yellow-200" : "")} onClick={setEdit}>
                {readableValue}
            </div>
        ) : null}

        {state === 'edit' ? (<div className={props.containerClass}>
            {props.input === 'text' ? (<input type={"text"} {...props.register} placeholder={props.inputPlaceholder || ""} autoFocus={true} onBlur={() => setState('idle')}/>) : null}
            {props.input === 'select' ? (<select {...props.register} autoFocus={true} onBlur={() => setState('idle')}>
                {props.options?.map(([value, data]) => (<option value={value}>{data}</option>))}
            </select>) : null}
            {props.input === 'textarea' ? (<textarea {...props.register} placeholder={props.inputPlaceholder || ""} autoFocus={true} onBlur={() => setState('idle')}/>) : null}
        </div>) : null}
    </>);
}