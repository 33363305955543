import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchSpeeddatingRequestsForEvent,
    selectSpeeddatingDayRequests,
    selectSpeeddatingRequestEditor,
    selectManagerEventState,
    setManagerEventState,
} from '../reducers/spedddatingRequests'
import {selectSpeeddatingRequestUserEditor} from '../reducers/users'
import {fetchSpeeddatingPublicEvents, selectSpeeddatingPublicEvents} from '../reducers/spedddatingEvents'
import RequestEditorForm from "../components/speeddatingManager/RequestEditorForm";
import VisitorCard from "../components/speeddatingManager/VisitorCard";
import UserEditForm from "../components/speeddatingManager/UserEditForm";
import UserSearchForm from "../components/speeddatingManager/UserSearchForm";
import requestSortFunction from "../util/requestSortFunction";
import moment from "moment";

export default function ManagerRequest() {

    const dispatch = useDispatch();
    const dayRequests = useSelector(selectSpeeddatingDayRequests);
    const events = useSelector(selectSpeeddatingPublicEvents);
    const requestEditor = useSelector(selectSpeeddatingRequestEditor);
    const userEditor = useSelector(selectSpeeddatingRequestUserEditor);
    const eventState = useSelector(selectManagerEventState);

    if (events.state === 'unknown') {
        dispatch(fetchSpeeddatingPublicEvents());
    }

    const onSelectEvent = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const event = e.target.value === "null" ? null : +e.target.value;
        if (event !== null) {
            dispatch(setManagerEventState({selected: event}))
            dispatch(fetchSpeeddatingRequestsForEvent());
        }
    }
    useEffect(() => {
        const timer = setTimeout(
            () => dispatch(fetchSpeeddatingRequestsForEvent()),
            10000
        );
        return () => clearTimeout(timer);
    });

    const [total, paidOnline, paidCash] = dayRequests.items
        .filter(item => (item.status_evt === "confirmed") || (item.status_day === "confirmed"))
        .reduce((a, c) => {
            return [
                a[0] + c.price,
                a[1] + c.paid_online,
                a[2] + c.paid_cash,
            ]
        }, [0, 0, 0]);

    return (
        <>
            <h1>Сводные таблицы</h1>

            Выберите вечеринку:
            <select onChange={onSelectEvent}>
                <option value="0">Не выбрано</option>
                {events.items.map((event => (
                    <option selected={event.id === eventState.selected} key={event.id} value={event.id}>{moment(event.date).format("dd DD.MM.YYYY HH:mm")} {event.speeddating_cafe?.name}</option>
                )))}
            </select>

            <div className="grid grid-rows-1 grid-cols-6 lg:grid-cols-12 gap-4">
                <div className="col-span-6" id={"js-manager-editor"}>
                    {userEditor.status === 'disabled' && requestEditor.status === 'idle' ? (<UserSearchForm/>) : null}
                    {userEditor.status !== 'disabled' && requestEditor.status !== 'idle' ? (<UserEditForm/>) : null}
                    {userEditor.status === 'disabled' && requestEditor.status !== 'idle' ? (<RequestEditorForm type="day" event={eventState.selected}/>) : null}
                </div>
                <div className="col-span-6">
                    <h2>Текущие записи {dayRequests.state === "fetching" ? "(...обновление...)" : null}</h2>

                    {dayRequests.state === "complete" ? (
                        <div className={"border border-blue-500 bg-blue-50 p-2"}>
                            <div>Всего за вечеринку должно быть получено <span className={"font-bold"}>{total}</span> руб.</div>
                            <div>Из них: <span className={"font-bold"}>{total - paidOnline}</span> руб. наличными и <span className={"font-bold"}>{paidOnline}</span> руб. олнайн</div>
                            <div>Получено наличными: <span className={"font-bold"}>{paidCash}</span></div>
                        </div>
                    ) : null}

                    <div className="grid grid-cols-2 gap-2">
                        <div className="col-start-1 col-end-2">
                            {dayRequests.items
                                .filter(item => item.gender !== 'm')
                                .sort(requestSortFunction)
                                .map((item) => (
                                    <VisitorCard key={item.id} req={item}/>
                                ))}
                        </div>
                        <div className="col-start-2 col-end-3">
                            {dayRequests.items
                                .filter(item => item.gender === 'm')
                                .sort(requestSortFunction)
                                .map((item) => (
                                    <VisitorCard key={item.id} req={item}/>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
