import React from "react";
import {SpeeddatingRequestWithUser} from "../../types/spedddating";
import moment from "moment";
import 'moment/locale/ru';
import {useDispatch} from "react-redux";
import {fetchSpeeddatingVisitorInfo, requestEdit} from "../../reducers/spedddatingRequests";
import cx from "classnames"
import {ttBirthAge} from "../../types/toText";
import {PHOTOS_BASE_URL} from "../../app.config";

export default function VisitorCardNewRequest(props: { req: SpeeddatingRequestWithUser }) {
    const req = props.req;

    const dispatch = useDispatch();

    const editRequest = () => {
        dispatch(requestEdit({id: req.id, data: req}));
        dispatch(fetchSpeeddatingVisitorInfo({id: req.id}));
        document.getElementById('js-manager-editor')?.scrollIntoView({behavior: "smooth"});
    }

    return (
        <div className={
            cx("grid grid-cols-6 lg:grid-cols-12 gap-2 w-container border-l-8 ", {
                "border-gray-400": req.status_reg === "new",
                "border-red-600": req.status_reg === "canceled",
                "border-yellow-500": req.status_reg === "na",
                "border-transparent": req.status_reg === "confirmed"
            })
        } onClick={editRequest}>
            <div className="col-span-6 flex flex-col lg:flex-row">
                <div className={"mr-2"}>
                    {req.user?.photos?.length ? (
                        <img className={"w-24 h-24"}
                             src={`${PHOTOS_BASE_URL}z${req.user.photos[0].dir}/${req.user.photos[0].thumb}`}
                             alt={""}/>
                    ) : (
                        <div className={"square bg-gray-400 w-24"}/>
                    )}
                </div>
                <div>
                    <div><span className="font-bold">{req.name} {req.name !== req.user?.display_name ? req.user?.display_name : null} </span></div>
                    <div><a className={"link"} href={`tel:+${req.tel}`}>+{req.tel}</a></div>
                    <div>{req.age ? `Возраст (заявка): ${req.age}` : ttBirthAge(req.user?.birth || null, req.user?.age || null, req.user?.birth_year || null)}</div>
                </div>
            </div>
            <div className="col-span-6">
                <div>{req.speeddating_event?.speeddating_cafe?.name}</div>
                <div>{moment(req.speeddating_event?.date).locale('ru').format("ddd DD.MM HH:mm")}</div>
                <div><span className={"text-gray-500"}>Статус:</span> {req.status_reg === "new" ? "Новая" : req.status_reg === "na" ? "Не отвечает" : `<${req.status_reg}>`}</div>
            </div>

        </div>
    )
}
