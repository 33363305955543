import React from "react"

export default function Base(props: React.PropsWithChildren<{}>) {
    return (
        <header>
            <div className="container mx-auto">
                {props.children}
            </div>
        </header>
    )
}