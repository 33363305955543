import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {PHOTOS_BASE_URL} from "../app.config"

import {
    selectUserEditorProfile,
    selectUserEditorState,
    performUserGetById,
    performUserUpdateById,
    performUserAddPhoto,
    performUserDeletePhoto,
    performUserSetMainPhoto,
    selectManagerList,
} from '../reducers/userEditor';

import {useFieldArray, useForm, useFormState} from "react-hook-form";
import {UserEditable} from "../types/spedddating";

import {Link} from "react-router-dom";
import EditableFragment from "../components/EditableFragment";
import Error from "../components/error";
import {ttBirthAge} from "../types/toText";
import { LocalizationContainer } from './userProfileViewComponents/LocalizationContainer';


export default function UserEditor() {

    const dispatch = useDispatch();
    const {id} = useParams() as { id: string };

    const userEditorProfile = useSelector(selectUserEditorProfile);
    const userEditorState = useSelector(selectUserEditorState);
    const managerList = useSelector(selectManagerList);

    // ================================ <CAFES FORM> ================================

    const {register, control, watch: profileWatch, reset, setValue} = useForm<{ d: NonNullable<UserEditable> }>();
    const watch = profileWatch();
    const {dirtyFields} = useFormState({control});


    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control,
        name: "d.user_profile.children_data"
    });

    if (userEditorProfile.data?.id !== +id) {
        dispatch(performUserGetById({id: +id}));
    }

    useEffect(() => {
        if (userEditorProfile.data) {
            reset({d: JSON.parse(JSON.stringify(userEditorProfile.data))});
        }
    }, [reset, userEditorProfile.data]);


    const setProfileFlags = ({deleted, hidden_candidate, hidden_call}: { deleted?: number, hidden_candidate?: number, hidden_call?: number }) => {
        if (userEditorProfile.data) {
            const data = { ...userEditorProfile.data };
            if (undefined !== deleted) data.deleted = deleted;
            if (undefined !== hidden_candidate) data.hidden_candidate = hidden_candidate;
            if (undefined !== hidden_call) data.hidden_call = hidden_call;
            dispatch(performUserUpdateById({ id: +id, data: data }));
        }
    }

    const onSaveProfile = () => {
        dispatch(performUserUpdateById({id: +id, data: watch.d}));
    }

    // ================================ UPLOAD PHOTO ================================
    const [photo, setPhoto] = useState({blob: "", file: null, h: 0, w: 0});
    const onPreviewPhoto = (e: any) => {
        setPhoto(() => {
            return {file: e.target.files[0], blob: URL.createObjectURL(e.target.files[0]), h: 0, w: 0}
        });
    };
    const onPhotoFormSubmit = (e: any) => {
        e.preventDefault();
        dispatch(performUserAddPhoto({userId: +id, photo: photo.file}));
    }
    const onPhotoFormReset = () => {
        setPhoto(() => {
            return {file: null, blob: "", h: 0, w: 0}
        });
    }

    const onDeletePhoto = (photoId: number) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Удалить фотографию?")) {
            dispatch(performUserDeletePhoto({userId: +id, photoId: photoId}));
        }
    }
    const onSetMainPhoto = (photoId: number) => {
        // eslint-disable-next-line no-restricted-globals
        dispatch(performUserSetMainPhoto({userId: +id, photoId: photoId}));
    }

    // ================================ <CAFES FORM> ================================

    const attitudeToText = (value: number): string => {
        if (value === -3) return "Резко негативное";
        else if (value === -2) return "Негативное";
        else if (value === -1) return "Слегка негативное";
        else if (value === 1) return "Слегка позитивное";
        else if (value === 2) return "Позитивное";
        else if (value === 3) return "Резко позитивное";
        return "Нейтральное";
    }
    const attitudeTo = [
        ["", "Не задано"],
        ["-3", "Резко негативное"],
        ["-2", "Негативное"],
        ["-1", "Слегка негативное"],
        ["0", "Нейтральное"],
        ["1", "Слегка позитивное"],
        ["2", "Позитивное"],
        ["3", "Резко позитивное"],
    ]
    const telToText = (value: string): string => {
        let m = value.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (m) return "+7 " + m.slice(1, 5).join(" ");
        return "+" + value;
    }

    return (
        <>
            <h1>Анкета пользователя</h1>

            {/* VIEW */}

            {!userEditorProfile.data ? (<div className={"px-2 py-2 border-blue-600 bg-blue-200"}>
                Идет загрузка...
            </div>) : null}

            {userEditorProfile.error ? <Error error={userEditorProfile.error}/> : null}

            {(userEditorProfile.data && watch.d && watch.d.user_profile) ? (<>
                <div className={"w-container"}>
                    <div className="grid grid-rows-1 lg:grid-cols-12 grid-cols-4 gap-4">
                        <div className={"lg:col-span-3 col-span-4 flex flex-col items-center"}>
                            {userEditorProfile.data.photos && userEditorProfile.data.photos.length ? (
                                <Link className={"w-full"} to={{pathname: `${PHOTOS_BASE_URL}z${userEditorProfile.data.photos[0].dir}/${userEditorProfile.data.photos[0].big}`}} target="_blank">
                                    <img className={"w-full"}
                                         src={`${PHOTOS_BASE_URL}z${userEditorProfile.data.photos[0].dir}/${userEditorProfile.data.photos[0].thumb}`}
                                         alt={""}/>
                                </Link>
                            ) : (
                                <div className={"h-36 w-36 bg-gray-400"}>&nbsp;</div>
                            )}
                        </div>
                        <div className={"lg:col-span-7 col-span-4"}>
                            <div className={"grid grid-cols-12 gap-4"}>
                                <div className={"col-span-8"}>
                                    <div>
                                        <EditableFragment containerClass={"inline-block text-2xl pr-4"}
                                                          register={register("d.display_name")}
                                                          isDirty={dirtyFields.d?.display_name || false}
                                                          value={watch.d.display_name || ""}
                                                          input={"text"}/>


                                        {watch.d.deleted ? (
                                            <span className={"bg-black text-white rounded px-2 mr-1"}>УД</span>
                                        ) : null}

                                        {userEditorProfile.data.is_agency_client ? (
                                            <span className={"bg-purple-600 text-white rounded px-2 mr-1"}>БА</span>
                                        ) : null}

                                        {userEditorProfile.data.hidden_candidate ? (
                                            <span className={"bg-gray-600 text-white rounded px-2 mr-1"} title={"БА: Кандидат спрятан"}>БА:СП</span>
                                        ) : null}

                                        {userEditorProfile.data.is_speeddating_client ? (
                                            <span className={"bg-green-600 text-white rounded px-2 mr-1"}>СВ</span>
                                        ) : null}

                                        {userEditorProfile.data.hidden_call ? (
                                            <span className={"bg-gray-600 text-white rounded px-2 mr-1"} title={"Исходящий: Кандидат спрятан"}>ЗВ:СП</span>
                                        ) : null}

                                        {userEditorProfile.data.is_expo_user ? (
                                          <span className={"bg-yellow-600 text-white rounded px-2 mr-1"} title={"Каталог: отображается"}>КАТ</span>
                                        ) : null}

                                    </div>

                                    <div className={"text-xs text-gray-500"}>
                                        <div className={"inline-block mr-1"}>{ttBirthAge(watch.d.birth, watch.d.age, watch.d.birth_year)}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={"pt-2 grid grid-cols-4"}>
                                <div className={"col-span-4 flex items-center"}>
                                    <div className={"pr-1"}>Телефон:</div>
                                    <EditableFragment containerClass={"inline"}
                                                      register={register("d.tel")}
                                                      isDirty={dirtyFields.d?.tel || false}
                                                      value={"+" + watch.d.tel || ""}
                                                      input={"text"}/>

                                    <div className={"pl-3 pr-1"}>e-mail:</div>
                                    <EditableFragment containerClass={"inline"}
                                                      register={register("d.email")}
                                                      isDirty={dirtyFields.d?.email || false}
                                                      value={watch.d.email || ""}
                                                      input={"text"}/>
                                </div>
                            </div>

                            <div className={"pt-2"}>
                                <span className={"text-gray-500"}>Менеджер ДНСВ: </span>
                                <EditableFragment containerClass={""}
                                                  register={register("d.speeddating_manager_id")}
                                                  isDirty={dirtyFields.d?.speeddating_manager_id || false}
                                                  value={watch.d?.speeddating_manager_id || ""}
                                                  input={"select"}
                                                  options={[["", "Не задано"], ...managerList.items.map(manager => [manager.id.toString(), manager.admin_privilege?.name || manager.display_name])]}/>
                            </div>

                            <div className={"pt-2"}>
                                <span className={"text-gray-500"}>О себе: </span>
                                <EditableFragment containerClass={""}
                                                  register={register("d.user_profile.about")}
                                                  isDirty={dirtyFields.d?.user_profile?.about || false}
                                                  value={watch.d.user_profile?.about || ""}
                                                  input={"textarea"}/>
                            </div>

                            <div className={"py-2"}>
                                <span className={"text-gray-500"}>Кого ищу: </span>
                                <EditableFragment containerClass={""}
                                                  register={register("d.user_profile.searching_for")}
                                                  isDirty={dirtyFields.d?.user_profile?.searching_for || false}
                                                  value={watch.d.user_profile?.searching_for || ""}
                                                  input={"textarea"}/>
                            </div>
                        </div>
                        <div className={"col-span-2"}>
                            <Link className={"btn mb-1 block text-center"} to={`/agency/${userEditorProfile.data.id}/view`}>БА профиль</Link>
                            <Link className={"btn mb-1 block text-center"} to={`/payments/new/${userEditorProfile.data.id}`}>Выставить счет</Link>
                            <br/>
                            {dirtyFields.d ? (<button className={"btn mb-1 block text-center w-full"} onClick={() => onSaveProfile()}>Сохранить</button>) : null}
                        </div>
                    </div>
                </div>

                {/* VIEW: PHOTOS */}
                <div className={"w-container"}>
                    <div className={"overflow-x-scroll"}>
                        <div className={"flex w-max"}>


                            {/* IMAGE UPLOAD FORM */}
                            <div className={"mx-2 h-48 w-48 relative"}>
                                <form onSubmit={onPhotoFormSubmit} onReset={onPhotoFormReset}>
                                    <div className="w-48 h-48 bg-contain bg-no-repeat" style={{backgroundImage: `url(${photo.blob})`}}/>

                                    <div className={"absolute flex flex-col items-center justify-center top-0 left-0 h-full w-full opacity-60"}>
                                        {photo.blob === "" ? (<>
                                            <label className={"btn mx-auto"}>Выбрать
                                                <input type="file" className={"opacity-0 z-0 absolute"} onChange={onPreviewPhoto}/>
                                            </label>
                                        </>) : (<>
                                            <button className="btn my-1 white block" type="reset">Сбросить</button>
                                            <button className="btn my-1 block" type="submit">Загрузить</button>
                                        </>)}
                                    </div>
                                </form>
                            </div>
                            {/* IMAGE UPLOAD FORM: END */}

                            {userEditorProfile.data.photos && userEditorProfile.data.photos.length ? userEditorProfile.data.photos.map(photo => (
                                <div className={"mx-2 h-48 w-48 relative"}>
                                    <button onClick={() => onDeletePhoto(photo.id)} className={"bg-gray-200 bg-opacity-50 h-6 w-6 block absolute right-0 top-0 icon text-2xl leading-6 cursor-pointer"}>close</button>
                                    <button onClick={() => onSetMainPhoto(photo.id)} className={"bg-gray-200 bg-opacity-50 h-6 w-6 block absolute left-0 top-0 icon text-2xl leading-6 cursor-pointer"}>star_border</button>
                                    <Link className={"h-48 w-48"} to={{pathname: `${PHOTOS_BASE_URL}z${photo.dir}/${photo.big}`}} target="_blank">
                                        <img className={"h-48 w-48"} src={`${PHOTOS_BASE_URL}z${photo.dir}/${photo.thumb}`} alt={""}/>
                                    </Link>
                                    {/*<div>*/}
                                    {/*    <button className={"icon"}>delete</button>*/}
                                    {/*    <button className={"icon"}>stars</button>*/}
                                    {/*</div>*/}
                                </div>
                            )) : null}
                        </div>
                    </div>
                </div>
                {/* VIEW: PHOTOS END */}

                <LocalizationContainer userId={id}/>

                <div className={"w-container"}>
                    <div className="grid grid-rows-1 lg:grid-cols-12 grid-cols-4 gap-8">
                        <div className={"lg:col-span-8 col-span-4 text-2xl uppercase"}>Профиль пользователя</div>
                        <div className={"lg:col-span-4 lg:block hidden text-2xl uppercase"}>Интересы</div>

                        <div className={"col-span-4"}>
                            {/* PROFILE COLUMN 1 START */}
                            <div className={'grid grid-cols-12 gap-y-1'}>
                                <div className={"col-span-6 text-gray-500 flex items-center"}>Гендер</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.gender")}
                                                  isDirty={dirtyFields.d?.gender || false}
                                                  value={watch.d.gender || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["u", "Не задано"],
                                                      ["f", "Ж"],
                                                      ["m", "М"],
                                                  ]}/>

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Дата рождения</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.birth")}
                                                  isDirty={dirtyFields.d?.birth || false}
                                                  value={watch.d.birth || ""}
                                                  input={"text"}/>

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Год рождения</div>
                                {watch.d.birth ? (<div className={"col-span-6 text-gray-800"}>&lt;авто&gt;</div>) : (
                                    <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                      register={register("d.birth_year")}
                                                      isDirty={dirtyFields.d?.birth_year || false}
                                                      value={watch.d.birth_year || ""}
                                                      input={"text"}/>
                                )}

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Рост</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.height")}
                                                  isDirty={dirtyFields.d?.user_profile?.height || false}
                                                  value={watch.d.user_profile?.height || ""}
                                                  input={"text"}/>

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Вес</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.weight")}
                                                  isDirty={dirtyFields.d?.user_profile?.weight || false}
                                                  value={watch.d.user_profile?.weight || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center flex items-center"}>Цвет волос</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.hair_color")}
                                                  isDirty={dirtyFields.d?.user_profile?.hair_color || false}
                                                  value={watch.d.user_profile.hair_color || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Брюнет", "Брюнет"],
                                                      ["Шатен", "Шатен"],
                                                      ["Блондин", "Блондин"],
                                                      ["Рыжий", "Рыжий"],
                                                      ["Русый", "Русый"],
                                                      ["Седой", "Седой"],
                                                      ["Лысый", "Лысый"],
                                                  ]}/>

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Цвет глаз</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.eyes_color")}
                                                  isDirty={dirtyFields.d?.user_profile?.eyes_color || false}
                                                  value={watch.d.user_profile.eyes_color || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["синие", "Синие"],
                                                      ["голубые", "Голубые"],
                                                      ["серые", "Серые"],
                                                      ["зеленые", "Зеленые"],
                                                      ["карие", "Карие"],
                                                      ["черные", "Черные"],
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Телосложение</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.body_type")}
                                                  isDirty={dirtyFields.d?.user_profile?.body_type || false}
                                                  value={watch.d.user_profile.body_type || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Обычный", "Обычный"],
                                                      ["Худой", "Худой"],
                                                      ["Спортивный", "Спортивный"],
                                                      ["Полный", "Полный"],
                                                      ["Очень большой", "Очень большой"],
                                                  ]}/>
                                <div className={"col-span-6 text-gray-500 flex items-center"}>Место рождения</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.birth_location")}
                                                  isDirty={dirtyFields.d?.user_profile?.birth_location || false}
                                                  value={watch.d.user_profile.birth_location || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Город</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.city")}
                                                  isDirty={dirtyFields.d?.user_profile?.city || false}
                                                  value={watch.d.user_profile.city || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Район</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.district")}
                                                  isDirty={dirtyFields.d?.user_profile?.district || false}
                                                  value={watch.d.user_profile.district || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Татуировки</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.tattoo")}
                                                  isDirty={dirtyFields.d?.user_profile?.tattoo || false}
                                                  value={watch.d.user_profile.tattoo || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Пирсинг</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.piercing")}
                                                  isDirty={dirtyFields.d?.user_profile?.piercing || false}
                                                  value={watch.d.user_profile.piercing || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Национальность</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.nationality")}
                                                  isDirty={dirtyFields.d?.user_profile?.nationality || false}
                                                  value={watch.d.user_profile.nationality || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Вероисповедание</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.religion")}
                                                  isDirty={dirtyFields.d?.user_profile?.religion || false}
                                                  value={watch.d.user_profile.religion || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Христианство", "Христианство"],
                                                      ["Ислам", "Ислам"],
                                                      ["Буддизм", "Буддизм"],
                                                      ["Иудаизм", "Иудаизм"],
                                                      ["Атеизм", "Атеизм"],
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Семейный статус</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.martial_status")}
                                                  isDirty={dirtyFields.d?.user_profile?.martial_status || false}
                                                  value={watch.d.user_profile.martial_status || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Cвободен", "Cвободен"],
                                                      ["В активном поиске", "В активном поиске"],
                                                      ["Готов к знакомствам", "Готов к знакомствам"],
                                                      ["Встречаюсь", "Встречаюсь"],
                                                      ["Женат", "Женат"],
                                                      ["В разводе", "В разводе"],
                                                      ["Вдовец", "Вдовец"],
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Дети</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.children")}
                                                  isDirty={dirtyFields.d?.user_profile?.children || false}
                                                  value={watch.d.user_profile.children || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да, 1"],
                                                      ["2", "Да, 2"],
                                                      ["3", "Да, 3"],
                                                      ["4", "Да, 4 или больше"],
                                                  ]}/>

                                {/*<div className={"col-span-6 text-gray-500 flex items-center"}>Задать детей</div>*/}
                                {/*<div className={"col-span-6 text-gray-800"}>*/}
                                {/*    {fields.map((item, index) => (*/}
                                {/*        <div key={item.id}>*/}
                                {/*            <input type={"text"}*/}
                                {/*                {...register(`d.user_profile.children_data.${index}.birth_year`)}*/}
                                {/*                defaultValue={item.birth_year} // make sure to set up defaultValue*/}
                                {/*            />*/}
                                {/*            <input type={"text"}*/}
                                {/*                {...register(`d.user_profile.children_data.${index}.gender`)}*/}
                                {/*                defaultValue={item.gender} // make sure to set up defaultValue*/}
                                {/*            />*/}

                                {/*            <button className={"btn"} type="button" onClick={() => remove(index)}>Delete</button>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}

                                {/*    <button className={"btn"}  type="button" onClick={() => append({gender: "", birth_year: ""})}>+1</button>*/}
                                {/*</div>*/}

                                {/*<div className={"col-span-6 text-gray-800"}>{userEditorProfile.data.user_profile.children_data.map(child => (*/}
                                {/*    <div>*/}
                                {/*        {child.gender === "m" ? "Мальчик" : "Девочка"}, {child.birth_year} г.р.*/}
                                {/*    </div>))}</div>*/}


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Хочу детей</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.wait_children")}
                                                  isDirty={dirtyFields.d?.user_profile?.wait_children || false}
                                                  value={watch.d.user_profile.wait_children || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                            </div>
                            {/* PROFILE COLUMN 1 END */}
                        </div>
                        <div className={"col-span-4"}>
                            {/* PROFILE COLUMN 2 START */}
                            <div className={'grid grid-cols-12 gap-y-1'}>
                                <div className={"col-span-6 text-gray-500 flex items-center"}>Наличие жилья</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.housing_ownership")}
                                                  isDirty={dirtyFields.d?.user_profile?.housing_ownership || false}
                                                  value={watch.d.user_profile.housing_ownership || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Тип жилья</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.housing_type")}
                                                  isDirty={dirtyFields.d?.user_profile?.housing_type || false}
                                                  value={watch.d.user_profile.housing_type || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Дом", "Дом"],
                                                      ["Квартира", "Квартира"]
                                                  ]}/>

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Наличие автомобиля</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.auto")}
                                                  isDirty={dirtyFields.d?.user_profile?.auto || false}
                                                  value={watch.d.user_profile.auto || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Модель автомобиля</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.auto_model")}
                                                  isDirty={dirtyFields.d?.user_profile?.auto_model || false}
                                                  value={watch.d.user_profile.auto_model || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Водительские права</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.driver_license")}
                                                  isDirty={dirtyFields.d?.user_profile?.driver_license || false}
                                                  value={watch.d.user_profile.driver_license || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>С кем живу</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.cohabitant")}
                                                  isDirty={dirtyFields.d?.user_profile?.cohabitant || false}
                                                  value={watch.d.user_profile.cohabitant || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Один", "Один"],
                                                      ["С родителями", "С родителями"],
                                                      ["Со второй половинкой", "Со второй половинкой"],
                                                      ["С друзьями", "С друзьями"],
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Образование</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.education")}
                                                  isDirty={dirtyFields.d?.user_profile?.education || false}
                                                  value={watch.d.user_profile.education || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Среднее", "Среднее"],
                                                      ["Средне-специальное", "Средне-специальное"],
                                                      ["Студент", "Студент"],
                                                      ["Неполное высшее", "Неполное высшее"],
                                                      ["Высшее", "Высшее"],
                                                      ["Несколько высших", "Несколько высших"],
                                                      ["Научная степень", "Научная степень"],
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Сфера деятельности</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.field_of_activity")}
                                                  isDirty={dirtyFields.d?.user_profile?.field_of_activity || false}
                                                  value={watch.d.user_profile.field_of_activity || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["Госслужба и некоммерческие организации", "Госслужба и некоммерческие организации"],
                                                      ["Банки и страховые компании", "Банки и страховые компании"],
                                                      ["Бухгалтерия и финансы предприятия", "Бухгалтерия и финансы предприятия"],
                                                      ["Торговля", "Торговля"],
                                                      ["Строительство и недвижимость", "Строительство и недвижимость"],
                                                      ["Искусство, творчество и развлечения", "Искусство, творчество и развлечения"],
                                                      ["Информационные технологии, интернет и телеком", "Информационные технологии, интернет и телеком"],
                                                      ["Маркетинг и реклама", "Маркетинг и реклама"],
                                                      ["Медицина и фармацевтика", "Медицина и фармацевтика"],
                                                      ["Наука и образование", "Наука и образование"],
                                                      ["Производство", "Производство"],
                                                      ["Рабочий персонал", "Рабочий персонал"],
                                                      ["Спортивные клубы и салоны красоты", "Спортивные клубы и салоны красоты"],
                                                      ["Туризм, рестораны, гостиницы", "Туризм, рестораны, гостиницы"],
                                                      ["Работа с персоналом", "Работа с персоналом"],
                                                      ["Юристы", "Юристы"],
                                                      ["Продажи", "Продажи"],
                                                      ["Транспорт и логистика", "Транспорт и логистика"],
                                                      ["Административный персонал", "Административный персонал"],
                                                      ["Домохозяйка", "Домохозяйка"],
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Позиция в компании</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.company_position")}
                                                  isDirty={dirtyFields.d?.user_profile?.company_position || false}
                                                  value={watch.d.user_profile.company_position || ""}
                                                  input={"text"}/>

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Свой бизнес</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.own_business")}
                                                  isDirty={dirtyFields.d?.user_profile?.own_business || false}
                                                  value={watch.d.user_profile.own_business || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Сфера бизнеса</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.own_business_area")}
                                                  isDirty={dirtyFields.d?.user_profile?.own_business_area || false}
                                                  value={watch.d.user_profile.own_business_area || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Доход</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.income")}
                                                  isDirty={dirtyFields.d?.user_profile?.income || false}
                                                  value={watch.d.user_profile.income || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Наличие питомцев</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.has_pets")}
                                                  isDirty={dirtyFields.d?.user_profile?.has_pets || false}
                                                  value={watch.d.user_profile.has_pets || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                                {/*<div className={"col-span-6 text-gray-500 flex items-center"}>Питомцы</div>*/}
                                {/*<div className={"col-span-6 text-gray-800"}>{userEditorProfile.data.user_profile.pets.join(", ")}</div>*/}


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Хобби</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.hobby")}
                                                  isDirty={dirtyFields.d?.user_profile?.hobby || false}
                                                  value={watch.d.user_profile.hobby || ""}
                                                  input={"text"}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Курение</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.smoking")}
                                                  isDirty={dirtyFields.d?.user_profile?.smoking || false}
                                                  value={watch.d.user_profile.smoking || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>


                                <div className={"col-span-6 text-gray-500 flex items-center"}>Владение иностранными языками</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.foreign_languages")}
                                                  isDirty={dirtyFields.d?.user_profile?.foreign_languages || false}
                                                  value={watch.d.user_profile.foreign_languages || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>

                                {/*<div className={"col-span-6 text-gray-500 flex items-center"}>Иностранные языки</div>*/}
                                {/*<div className={"col-span-6 text-gray-800"}>{userEditorProfile.data.user_profile.foreign_languages_what.join(", ")}</div>*/}

                                <div className={"col-span-6 text-gray-500 flex items-center"}>Вегетарианец</div>
                                <EditableFragment containerClass={"col-span-6 text-gray-800"}
                                                  register={register("d.user_profile.vegan")}
                                                  isDirty={dirtyFields.d?.user_profile?.vegan || false}
                                                  value={watch.d.user_profile.vegan || ""}
                                                  input={"select"}
                                                  options={[
                                                      ["", "Не задано"],
                                                      ["0", "Нет"],
                                                      ["1", "Да"]
                                                  ]}/>
                            </div>
                        </div>
                        <div className={"col-span-4"}>
                            <div className={'grid grid-cols-12 gap-y-4'}>
                                {userEditorProfile.data.user_profile.attitude_to_art !== null ? (<>
                                    <div className={"col-span-3 flex items-center justify-center"}>
                                        <div
                                            className={"border border-gray-800 rounded-full h-10 w-10 flex items-center justify-center"}>
                                            {watch.d?.user_profile?.attitude_to_art || "?"}
                                        </div>
                                    </div>
                                    <div className={"col-span-9"}>
                                        <div className={"text-gray-500"}>Отношение к искусству</div>
                                        <EditableFragment containerClass={"text-gray-800"}
                                                          register={register("d.user_profile.attitude_to_art")}
                                                          isDirty={dirtyFields.d?.user_profile?.attitude_to_art || false}
                                                          value={watch.d.user_profile.attitude_to_art || ""}
                                                          input={"select"}
                                                          options={attitudeTo}/>
                                    </div>
                                </>) : null}

                                {userEditorProfile.data.user_profile.attitude_to_travelling !== null ? (<>
                                    <div className={"col-span-3 flex items-center justify-center"}>
                                        <div
                                            className={"border border-gray-800 rounded-full h-10 w-10 flex items-center justify-center"}>
                                            {watch.d?.user_profile?.attitude_to_travelling || "?"}
                                        </div>
                                    </div>
                                    <div className={"col-span-9"}>
                                        <div className={"col-span-12 text-gray-500"}>Отношение к путешествиям</div>
                                        <EditableFragment containerClass={"text-gray-800"}
                                                          register={register("d.user_profile.attitude_to_travelling")}
                                                          isDirty={dirtyFields.d?.user_profile?.attitude_to_travelling || false}
                                                          value={watch.d.user_profile.attitude_to_travelling || ""}
                                                          input={"select"}
                                                          options={attitudeTo}/>
                                    </div>
                                </>) : null}

                                {userEditorProfile.data.user_profile.attitude_to_pets !== null ? (<>
                                    <div className={"col-span-3 flex items-center justify-center"}>
                                        <div className={"border border-gray-800 rounded-full h-10 w-10 flex items-center justify-center"}>
                                            {watch.d?.user_profile?.attitude_to_pets || "?"}
                                        </div>
                                    </div>
                                    <div className={"col-span-9"}>
                                        <div className={"col-span-12 text-gray-500"}>Отношение к питомцам</div>
                                        <EditableFragment containerClass={"text-gray-800"}
                                                          register={register("d.user_profile.attitude_to_pets")}
                                                          isDirty={dirtyFields.d?.user_profile?.attitude_to_pets || false}
                                                          value={watch.d.user_profile.attitude_to_pets || ""}
                                                          input={"select"}
                                                          options={attitudeTo}/>
                                    </div>
                                </>) : null}

                                {userEditorProfile.data.user_profile.attitude_to_sport !== null ? (<>
                                    <div className={"col-span-3 flex items-center justify-center"}>
                                        <div
                                            className={"border border-gray-800 rounded-full h-10 w-10 flex items-center justify-center"}>
                                            {watch.d?.user_profile?.attitude_to_sport || "?"}
                                        </div>
                                    </div>
                                    <div className={"col-span-9"}>
                                        <div className={"col-span-12 text-gray-500"}>Отношение к спорту</div>
                                        <EditableFragment containerClass={"text-gray-800"}
                                                          register={register("d.user_profile.attitude_to_sport")}
                                                          isDirty={dirtyFields.d?.user_profile?.attitude_to_sport || false}
                                                          value={watch.d.user_profile.attitude_to_sport || ""}
                                                          input={"select"}
                                                          options={attitudeTo}/>
                                    </div>
                                </>) : null}

                                {userEditorProfile.data.user_profile.attitude_to_smoking !== null ? (<>
                                    <div className={"col-span-3 flex items-center justify-center"}>
                                        <div
                                            className={"border border-gray-800 rounded-full h-10 w-10 flex items-center justify-center"}>
                                            {watch.d?.user_profile?.attitude_to_smoking || "?"}
                                        </div>
                                    </div>
                                    <div className={"col-span-9"}>
                                        <div className={"col-span-12 text-gray-500"}>Отношение к курению</div>
                                        <EditableFragment containerClass={"text-gray-800"}
                                                          register={register("d.user_profile.attitude_to_smoking")}
                                                          isDirty={dirtyFields.d?.user_profile?.attitude_to_smoking || false}
                                                          value={watch.d.user_profile.attitude_to_smoking || ""}
                                                          input={"select"}
                                                          options={attitudeTo}/>
                                    </div>
                                </>) : null}

                                {userEditorProfile.data.user_profile.attitude_to_alcohol !== null ? (<>
                                    <div className={"col-span-3 flex items-center justify-center"}>
                                        <div
                                            className={"border border-gray-800 rounded-full h-10 w-10 flex items-center justify-center"}>
                                            {watch.d?.user_profile?.attitude_to_alcohol || "?"}
                                        </div>
                                    </div>
                                    <div className={"col-span-9"}>
                                        <div className={"col-span-12 text-gray-500"}>Отношение а алкоголю</div>
                                        <EditableFragment containerClass={"text-gray-800"}
                                                          register={register("d.user_profile.attitude_to_alcohol")}
                                                          isDirty={dirtyFields.d?.user_profile?.attitude_to_alcohol || false}
                                                          value={watch.d.user_profile.attitude_to_alcohol || ""}
                                                          input={"select"}
                                                          options={attitudeTo}/>
                                    </div>
                                </>) : null}

                                {userEditorProfile.data.user_profile.attitude_to_drugs !== null ? (<>
                                    <div className={"col-span-3 flex items-center justify-center"}>
                                        <div
                                            className={"border border-gray-800 rounded-full h-10 w-10 flex items-center justify-center"}>
                                            {watch.d?.user_profile?.attitude_to_drugs || "?"}
                                        </div>
                                    </div>
                                    <div className={"col-span-9"}>
                                        <div className={"col-span-12 text-gray-500"}>Отношение к наркотикам</div>
                                        <EditableFragment containerClass={"text-gray-800"}
                                                          register={register("d.user_profile.attitude_to_drugs")}
                                                          isDirty={dirtyFields.d?.user_profile?.attitude_to_drugs || false}
                                                          value={watch.d.user_profile.attitude_to_drugs || ""}
                                                          input={"select"}
                                                          options={attitudeTo}/>
                                    </div>
                                </>) : null}
                            </div>
                        </div>

                        {userEditorState.error ? <div className={"lg:col-span-12 col-span-4"}><Error error={userEditorState.error}/></div> : null}

                        {dirtyFields.d ? (<>
                            <div className={"lg:col-span-12 col-span-4 text-right"}>
                                <button className={"btn"} onClick={() => onSaveProfile()}>Сохранить</button>
                            </div>
                        </>) : null}
                    </div>
                </div>

                {/* VIEW: ACTIONS START */}
                <div className={"w-container"}>
                    <h2>Действия</h2>

                    <div className={"grid grid-cols-12 gap-2"}>
                        <div className={"col-span-2"}>
                            <div><span className={"font-bold"}>Удален: </span>{userEditorProfile.data.deleted ? "Да" : "Нет"}</div>
                            {userEditorProfile.data.deleted ? (
                                <button className={"btn green"} onClick={() => setProfileFlags({deleted: 0})}>Восстановить</button>
                            ) : (
                                <button className={"btn red"} onClick={() => setProfileFlags({deleted: 1})}>Удалить</button>
                            )}
                        </div>
                        <div className={"col-span-2"}>
                            <div><span className={"font-bold"}>Кандидат: </span>{userEditorProfile.data.hidden_candidate ? "Спрятан" : "Отображается"}</div>
                            {userEditorProfile.data.hidden_candidate ? (
                                <button className={"btn green"} onClick={() => setProfileFlags({hidden_candidate: 0})}>Отображать</button>
                            ) : (
                                <button className={"btn red"} onClick={() => setProfileFlags({hidden_candidate: 1})}>Спрятать</button>
                            )}
                        </div>
                        <div className={"col-span-2"}>
                            <div><span className={"font-bold"}>Звонок: </span>{userEditorProfile.data.hidden_call ? "Спрятан" : "Отображается"}</div>
                            {userEditorProfile.data.hidden_call ? (
                                <button className={"btn green"} onClick={() => setProfileFlags({hidden_call: 0})}>Отображать</button>
                            ) : (
                                <button className={"btn red"} onClick={() => setProfileFlags({hidden_call: 1})}>Спрятать</button>
                            )}
                        </div>
                    </div>

                </div>
                {/* VIEW: ACTIONS END */}
            </>) : null}


            {/* VIEW END */}


        </>)

}

