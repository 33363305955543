import {SpeeddatingRequest} from "../types/spedddating";

export default function requestSortFunction(itemA: SpeeddatingRequest, itemB: SpeeddatingRequest) {
    const statusEvtIndexes: Record<SpeeddatingRequest["status_evt"], number> = {
        "confirmed": 500,
        "late": 400,
        "waiting": 300,
        "canceled": 200,
        "na": 200,
    }
    const statusDayIndexes: Record<SpeeddatingRequest["status_day"], number> = {
        "confirmed": 50,
        "na": 40,
        "new": 30,
        "canceled": 20
    }
    const statusRegIndexes: Record<SpeeddatingRequest["status_reg"], number> = {
        "confirmed": 50,
        "na": 40,
        "new": 30,
        "canceled": 20
    }

    return (statusEvtIndexes[itemB.status_evt] + statusDayIndexes[itemB.status_day] + statusRegIndexes[itemB.status_reg]) - (statusEvtIndexes[itemA.status_evt] + statusDayIndexes[itemA.status_day] + statusRegIndexes[itemA.status_reg])
}