import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from "../store";
import axios from "axios";
import {BASE_URL} from "../app.config"

import {ApiError, SpeeddatingEvent,} from "../types/spedddating";


// Api error
const apiErrorHandler = (error: any) => {
    if (error.response && error.response.data) {
        if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
            throw new ApiError(error.response.data);
        }
    }

    throw error;
}

interface EventReduxInitState {
    publicEvents: {
        state: 'unknown' | 'fetching' | 'complete' | 'failed',
        items: Array<SpeeddatingEvent>,
        by_id: Record<number | string, SpeeddatingEvent>,
    };
    allEvents: {
        wet: boolean,
        state: 'unknown' | 'fetching' | 'complete' | 'failed',
        items: Array<SpeeddatingEvent>,
        by_id: Record<number|string, SpeeddatingEvent>,
        error: string;
    };
}

const initialState: EventReduxInitState = {
    publicEvents: {
        state: 'unknown',
        items: [],
        by_id: {}
    },
    allEvents: {
        wet: true,
        state: 'unknown',
        items: [],
        by_id: {},
        error: ''
    }
};


export const fetchSpeeddatingPublicEvents = createAsyncThunk(
    'speeddating/pubevent/list',
    async () => {
        const result = await axios(`${BASE_URL}/speeddating/event?extend=true`, {
            method: 'get',
            withCredentials: true,
        }).catch(apiErrorHandler);
        return result.data;
    }
);

export const fetchSpeeddatingAllEvents = createAsyncThunk(
    'speeddating/event/list',
    async (r?: {date_from: string, date_to: string}) => {
        const result = await axios(`${BASE_URL}/speeddating/event/list`, {
            method: 'get',
            withCredentials: true,
        }).catch(apiErrorHandler);
        return result.data;
    }
);


export const counterSlice = createSlice({
    name: 'speeddating_event',
    initialState,
    reducers: {

    },
    extraReducers: builder => {

        builder.addCase(fetchSpeeddatingPublicEvents.pending, (state, action) => {
            state.publicEvents.state = 'fetching';
        });
        builder.addCase(fetchSpeeddatingPublicEvents.fulfilled, (state, action) => {
            state.publicEvents.state = 'complete';
            state.publicEvents.items = action.payload.response;
            state.publicEvents.items.forEach(event => {
                state.publicEvents.by_id[event.id] = event;
            });
        });

        builder.addCase(fetchSpeeddatingAllEvents.pending, (state, action) => {
            state.allEvents.wet = false;
            state.allEvents.state = 'fetching';
        });
        builder.addCase(fetchSpeeddatingAllEvents.rejected, (state, action) => {
            state.allEvents.state = 'failed';
            state.allEvents.items = [];
            state.allEvents.error = JSON.stringify(action.error, null, 2);
        });
        builder.addCase(fetchSpeeddatingAllEvents.fulfilled, (state, action) => {
            state.allEvents.state = 'complete';
            state.allEvents.items = action.payload.response;
        });
    }
});


export const selectSpeeddatingPublicEvents = (state: RootState) => state.speeddating_event.publicEvents;
export const selectSpeeddatingAllEvents = (state: RootState) => state.speeddating_event.allEvents;


// export const {newRequestEdit, newRequestReset} = counterSlice.actions;

export default counterSlice.reducer;