import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PHOTOS_BASE_URL} from "../app.config"

import {
    selectUserEditorListQuery, selectCandidateList, userListQuerySet, userListReset, performUserListQuery, performAgencyCandidatesSearch, performAgencyMatchStatusUpdate, selectUserEditorList
} from '../reducers/userEditor'

import {useForm} from "react-hook-form";
import {UserListFilter} from "../types/spedddating";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import {useParams} from "react-router-dom";
import UserPreviewBig from "../components/UserPreviewBig";
import UserFilterForm from "../components/UserFilterForm";
import {getLoginState} from "../reducers/login";


export default function UserEditor() {
    const {id} = useParams() as { id: string };

    const dispatch = useDispatch();
    const userListQuery = useSelector(selectUserEditorListQuery);
    const userList = useSelector(selectUserEditorList);

    const onReload = (filter: UserListFilter, page: number) => {
        let offset = Math.ceil(page * userListQuery.limit);
        dispatch(userListQuerySet({filter, offset: offset, order: "id"}));
        dispatch(performUserListQuery({forAgencyClient: +id}));
    }

    useEffect(() => {
        // On reload: reset data
        dispatch(userListReset());
    }, []);

    // -------

    const [candidates, setCandidates] = useState<Record<number, boolean>>({})
    const onSetMatch = (match_id: number) => {
        setCandidates(state => ({...state, [match_id]: true}));
        dispatch(performAgencyMatchStatusUpdate({id: +id, data: {match_id, comment: "", status: "candidate"}}))
    }

    // ================================ <CAFES FORM> ================================


    return (
        <>
            <div className={"grid grid-cols-12"}>
                <div className={"col-span-9"}>
                    <h1>Поиск кандидатов для клиента</h1>
                </div>
                <div className={"col-span-3 text-right"}>
                    <a className={"btn my-4"} href={`/agency/${id}/view`}>К кандидату</a>
                </div>
            </div>

            <UserFilterForm onUpdate={onReload} mode={"global"} forceFilters={{hidden_candidate: "0"}}>
                {userList.items.length > 0 ? userList.items.map(user => (
                    <UserPreviewBig key={user.id} user={user} agency={true} buttons={<>
                        {+id !== 0 ? (<>
                            {!candidates[user.id] ? (
                                <button className={"btn"} onClick={() => onSetMatch(user.id)}>Кандидат</button>
                            ) : (
                                <div className={"btn gray-100"}>Кандидат</div>
                            )};
                        </>): null}
                    </>}/>
                )) : null}
            </UserFilterForm>
        </>)
}