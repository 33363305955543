import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {PHOTOS_BASE_URL} from "../app.config"

import {
    selectUserEditorProfile,
    performUserGetById,
    userProfileSet,
    performAgencyMatchStatusUpdate,
    performAgencyClientTaskUpdate,
    performAgencyClientStatusUpdate,
    performAgencyMatchDelete,
    performUserUpdateById,
    selectManagerList
} from '../reducers/userEditor'

import {useForm, useFormState} from "react-hook-form";
import {AgencyClientMatch, AgencyClientMatchEditable, AgencyClientStatusEditable, AgencyClientTaskEditable, UserEditable} from "../types/spedddating";
import moment from "moment";
import {Link} from "react-router-dom";
import {ttAgencyCandidateStatus, ttAgencyStatus, ttBirthAge} from "../types/toText";
import EditableFragment from "../components/EditableFragment";
import {getLoginState} from "../reducers/login";
import ExpoContainer from './userProfileAgencyComponents/ExpoContainer';


export default function UserEditor() {
    const dispatch = useDispatch();
    const {id} = useParams() as { id: string };

    const loginState = useSelector(getLoginState);
    const userEditorProfile = useSelector(selectUserEditorProfile);
    const managerList = useSelector(selectManagerList);

    if (userEditorProfile.data?.id !== +id) {
        dispatch(performUserGetById({id: +id}));
    }

    // ================================ <CAFES FORM> ================================

    const {register, control, watch: profileWatch, reset, setValue} = useForm<{ d: NonNullable<UserEditable> }>();
    const watch = profileWatch();
    const {dirtyFields} = useFormState({control});

    useEffect(() => {
        if (userEditorProfile.data) {
            reset({d: JSON.parse(JSON.stringify(userEditorProfile.data))});
        }
    }, [reset, userEditorProfile.data]);

    const onSaveProfile = () => {
        dispatch(performUserUpdateById({id: +id, data: watch.d}));
    }

    // ================================ <CAFES FORM> ================================

    const {register: regMatch, handleSubmit: sumMatch, reset: rstMarch} = useForm<AgencyClientMatchEditable>();
    const [clientStatus, setClientStatus] = useState<AgencyClientMatchEditable>({match_id: 0, status: "none", comment: ""})

    const onEditMatchActivate = (match: AgencyClientMatch) => {
        setClientStatus({
            match_id: match.match_id,
            status: match.status,
            comment: match.comment,
        });
        rstMarch({
            match_id: match.match_id,
            status: match.status,
            comment: match.comment,
        })
        dispatch(userProfileSet({microFormsEditing: true}));
    }

    const onEditMatchSubmit = (data: AgencyClientMatchEditable) => {
        dispatch(performAgencyMatchStatusUpdate({id: +id, data: data}));
    }

    const onEditMatchReset = () => {
        setClientStatus({
            match_id: 0,
            status: "none",
            comment: ""
        });
        dispatch(userProfileSet({microFormsEditing: false}));
    }

    // ================================ <CAFES FORM> ================================

    const deleteCandidate = (id: number, match_id: number) => {
      // eslint-disable-next-line no-restricted-globals
        if (confirm("Точно удалить?")) {
            dispatch(performAgencyMatchDelete({id: +id, match_id: +match_id}));
        }
    }

    // ================================ <CAFES FORM> ================================

    const [editingStatus, setEditingStatus] = useState<boolean>(false);
    const {register: editStatusRegister, handleSubmit: editStatusSubmit} = useForm<AgencyClientStatusEditable>();

    const onEditStatusActivate = () => {
        setEditingStatus(true);
        dispatch(userProfileSet({microFormsEditing: true}));
    }
    const onEditStatusSubmit = (data: any) => {
        data.price = +data.price;
        dispatch(performAgencyClientStatusUpdate({id: +id, data: data}));
    }
    const onEditStatusReset = () => {
        setEditingStatus(false);
        dispatch(userProfileSet({microFormsEditing: false}));
    }

    // ================================ <CAFES FORM> ================================

    const [editingTask, setEditingTask] = useState<boolean>(false);
    const {register: editTaskRegister, handleSubmit: editTaskSubmit} = useForm<AgencyClientTaskEditable>();

    const onEditTaskActivate = () => {
        setEditingTask(true);
        dispatch(userProfileSet({microFormsEditing: true}));
    }
    const onEditTaskSubmit = (data: any) => {
        dispatch(performAgencyClientTaskUpdate({id: +id, data: data}));
    }
    const onEditTaskReset = () => {
        setEditingTask(false);
        dispatch(userProfileSet({microFormsEditing: false}));
    }

    // ================================ <CAFES FORM> ================================

    const [candidateFilter, setCandidateFilter] = useState("");
    const [candidateFilterTel, setCandidateFilterTel] = useState("");

    // ================================ <CAFES FORM> ================================


    useEffect(() => {
        console.log("close forms", Date.now())
        setClientStatus({
            match_id: 0,
            status: "none",
            comment: ""
        });
        setEditingStatus(false);
        setEditingTask(false);
    }, [userEditorProfile])

    // ================================ <CAFES FORM> ================================

    const attitudeToText = (value: number): string => {
        if (value === -3) return "Резко негативное";
        else if (value === -2) return "Негативное";
        else if (value === -1) return "Слегка негативное";
        else if (value === 1) return "Слегка позитивное";
        else if (value === 2) return "Позитивное";
        else if (value === 3) return "Резко позитивное";
        return "Нейтральное";
    }
    const telToText = (value: string): string => {
        let m = value.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (m) return "+7 " + m.slice(1, 5).join(" ");
        return "+" + value;
    }

    // Status
    const candidateStatuses = ['none', 'candidate', 'not_interested', 'ready', 'phones', 'planning', 'meeting', 'dislike'];

    // Task
    const task = {
        active: false,
        plan: 0,
        failing: false,
    }
    if (userEditorProfile.data && userEditorProfile.data.agency_client_tasks && userEditorProfile.data.agency_client_tasks.length) {
        const _task = userEditorProfile.data.agency_client_tasks[0];

        const _dateStart = new Date(_task.date).getTime();
        const _dateEnd = new Date(_task.ends).getTime();
        const _dateNow = new Date().getTime();

        let _plannedClients = _task.target_users * (_dateNow - _dateStart) / (_dateEnd - _dateStart);
        if (_plannedClients < 0) _plannedClients = 0;
        else if (_plannedClients > _task.target_users) _plannedClients = _task.target_users;
        else _plannedClients = Math.round(_plannedClients);

        task.active = true;
        task.plan = _plannedClients;
        task.failing = (_plannedClients > _task.target_found);
    }

    if(!loginState.admin_privilege.agency) return (<>Вам сюда нельзя</>);

    return (
        <>
            <h1>Анкета клиента брачного агентства</h1>

            {/* VIEW */}

            {userEditorProfile.data ? (<>
                <div className={"w-container"}>
                    <div className="grid grid-rows-1 lg:grid-cols-12 grid-cols-4 gap-4">
                        <div className={"lg:col-span-3 col-span-4 flex flex-col items-center"}>
                            {userEditorProfile.data.photos && userEditorProfile.data.photos.length ? (
                                <Link className={"w-full"} to={{pathname: `${PHOTOS_BASE_URL}z${userEditorProfile.data.photos[0].dir}/${userEditorProfile.data.photos[0].big}`}} target="_blank">
                                    <img className={"w-full"}
                                         src={`${PHOTOS_BASE_URL}z${userEditorProfile.data.photos[0].dir}/${userEditorProfile.data.photos[0].thumb}`}
                                         alt={""}/>
                                </Link>
                            ) : (
                                <div className={"h-36 w-36 bg-gray-400"}>&nbsp;</div>
                            )}
                        </div>
                        <div className={"lg:col-span-7 col-span-4"}>
                            <div className={"grid grid-cols-12 gap-4"}>
                                <div className={"col-span-8"}>
                                    <div>
                                        <span className={"text-2xl pr-4"}>{userEditorProfile.data.display_name}</span>

                                        {userEditorProfile.data.deleted ? (
                                            <span className={"bg-black text-white rounded px-2 mr-1"}>УД</span>
                                        ) : null}

                                        {userEditorProfile.data.is_agency_client ? (
                                            <span className={"bg-purple-600 text-white rounded px-2 mr-1"}>БА</span>
                                        ) : null}

                                        {userEditorProfile.data.hidden_candidate ? (
                                            <span className={"bg-gray-600 text-white rounded px-2 mr-1"} title={"БА: Кандидат спрятан"}>БА:СП</span>
                                        ) : null}

                                        {userEditorProfile.data.is_speeddating_client ? (
                                            <span className={"bg-green-600 text-white rounded px-2 mr-1"}>СВ</span>
                                        ) : null}

                                        {userEditorProfile.data.hidden_call ? (
                                            <span className={"bg-gray-600 text-white rounded px-2 mr-1"} title={"Исходящий: Кандидат спрятан"}>ЗВ:СП</span>
                                        ) : null}

                                    </div>
                                    <div className={"text-xs text-gray-500"}>Цель знакомства: {userEditorProfile.data.display_name}</div>
                                </div>
                            </div>

                            <div className={"pt-2"}>
                                <span className={"text-gray-500"}>Контактные данные: </span>
                                {telToText(userEditorProfile.data.tel)}
                                {userEditorProfile.data.email ? `, ${userEditorProfile.data.email}` : null}
                            </div>

                            <div className={"pt-2"}>
                                <span className={"text-gray-500"}>Менеджер: </span>
                                <EditableFragment containerClass={""}
                                                  register={register("d.agency_client.manager_id")}
                                                  isDirty={dirtyFields.d?.agency_client?.manager_id || false}
                                                  value={watch.d?.agency_client?.manager_id || ""}
                                                  input={"select"}
                                                  options={[["", "Не задано"], ...managerList.items.map(manager => [manager.id.toString(), manager.admin_privilege?.name || manager.display_name])]}/>
                            </div>

                            <div className={"pt-2"}>
                                <span className={"text-gray-500"}>О клиента: </span>
                                <EditableFragment containerClass={""}
                                                  register={register("d.agency_client.info")}
                                                  isDirty={dirtyFields.d?.agency_client?.info || false}
                                                  value={watch.d?.agency_client?.info || ""}
                                                  input={"textarea"}/>
                            </div>

                        </div>
                        <div className={"col-span-2"}>
                            <Link className={"btn mb-1 block text-center"} to={`/users/${userEditorProfile.data.id}/view`}>Профиль</Link>
                            <Link className={"btn mb-1 block text-center"} to={`/users/${userEditorProfile.data.id}/export`}>Экспорт профиля</Link>
                            <Link className={"btn mb-1 block text-center"} to={`/payments/new/${userEditorProfile.data.id}`}>Выставить счет</Link>

                            <br/>
                            {dirtyFields.d ? (<button className={"btn mb-1 block text-center w-full"} onClick={() => onSaveProfile()}>Сохранить</button>) : null}
                        </div>
                    </div>
                </div>

                <ExpoContainer key={'expoContainer'} profile={userEditorProfile} />

                {/* VIEW: AGENCY: START */}
                <div className={"w-container"}>
                    <div className={"grid lg:grid-cols-12 col-span-6 gap-4"}>
                        <div className={"col-span-6"}>
                            <span className={"text-2xl"}>Клиент</span>

                            {userEditorProfile.data.agency_client ? (<>
                                <div className={"pt-2"}>
                                    <span className={"text-gray-500"}>История: </span>
                                    {userEditorProfile.data.agency_client_statuses
                                        // ?.sort((s1, s2) => (moment(s1.date).unix() - moment(s2.date).unix()))
                                        ?.map(status => (
                                            <div key={status.id}>
                                                <span className={"text-gray-500"}>{moment(status.date).format("DD.MM.YYYY")}</span>: {ttAgencyStatus(status.status)} ({status.comment})
                                            </div>
                                        ))}
                                </div>
                            </>) : null}

                            <div className={"pt-4"}>
                                {!editingStatus ? (<>
                                    {userEditorProfile.data.agency_client ? (<>
                                        <div className={"flex items-center cursor-pointer"} onClick={onEditStatusActivate}>
                                            <div>
                                                {userEditorProfile.data.agency_client.status === 'contract' ? (<span className={"icon text-green-500"}>check_circle</span>) : null}
                                                {userEditorProfile.data.agency_client.status !== 'contract' ? (<span className={"icon text-yellow-500"}>error_outline</span>) : null}
                                            </div>
                                            <div className={"ml-2"}>
                                                <div className={"text-gray-500"}>Статус договора</div>
                                                <div>{ttAgencyStatus(userEditorProfile.data.agency_client.status)}</div>
                                            </div>
                                        </div>
                                    </>) : (<>
                                        <div className={"flex items-center cursor-pointer"} onClick={onEditStatusActivate}>
                                            <div>
                                                <span className={"icon text-red-500"}>error_outline</span>
                                            </div>
                                            <div className={"ml-2"}>
                                                Не является клиентом
                                            </div>
                                        </div>
                                    </>)}
                                </>) : (<>
                                    <form className={"grid grid-cols-12 gap-2 border border-gray-200 px-4 py-4 shadow"} onSubmit={editStatusSubmit(onEditStatusSubmit)} onReset={() => onEditStatusReset()}>
                                        <div className={"col-span-8"}>
                                            <label>Статус клиента</label>
                                            <select {...editStatusRegister("status")}>
                                                {['none', 'request', 'contract', 'finished', 'cancelled', 'expo_free', 'expo_paid'].map(value => (
                                                    <option value={value}>{ttAgencyStatus(value)}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className={"col-span-4"}>
                                            <label>Оплачено</label>
                                            <input type={"text"}  {...editStatusRegister("price")}/>
                                        </div>

                                        <div className={"col-span-12"}>
                                            <label>Комментарий</label>
                                            <input type={"text"} placeholder={"Комментарий"} {...editStatusRegister("comment")}/>
                                        </div>

                                        <button className={"btn white col-span-6"} type={"reset"}>Отменить</button>
                                        <button className={"btn col-span-6"} type={"submit"}>Сохранить</button>
                                    </form>
                                </>)}
                            </div>

                            {userEditorProfile.data.agency_client ? (<>
                                <div className={"pt-4"}>
                                    {!editingTask ? (<>
                                        {userEditorProfile.data.agency_client.status === 'contract' ? userEditorProfile.data.agency_client_tasks?.length ? (<>
                                            {/* CLIENT TASK DISPLAY: START */}
                                            <div className={"flex items-center cursor-pointer"} onClick={onEditTaskActivate}>
                                                <div>
                                                    {!task.failing ? <span className={"icon text-green-500"}>check_circle</span> : null}
                                                    {task.failing ? <span className={"icon text-red-500"}>check_circle</span> : null}
                                                </div>
                                                <div className={"ml-2"}>
                                                    <div>
                                                        <div className={"text-gray-500"}>
                                                            Задача по
                                                            договору {moment(userEditorProfile.data.agency_client_tasks[0].date).format("DD.MM.YYYY")} - {moment(userEditorProfile.data.agency_client_tasks[0].ends).format("DD.MM.YYYY")}
                                                        </div>
                                                        <div>Найти {userEditorProfile.data.agency_client_tasks[0].target_users - userEditorProfile.data.agency_client_tasks[0].target_found} из {userEditorProfile.data.agency_client_tasks[0].target_users} кандидатов</div>
                                                        {task.failing ?
                                                            <div className={"text-red-500"}> По плану: {task.plan} - отставание от плана!</div>
                                                            :
                                                            <div> По плану: {task.plan} - все нормально!</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* CLIENT TASK DISPLAY: END */}
                                        </>) : (<>
                                            <div className={"flex items-center cursor-pointer"} onClick={onEditTaskActivate}>
                                                <div>
                                                    <span className={"icon text-red-500"}>error_outline</span>
                                                </div>
                                                <div className={"ml-2"}>
                                                    Задача не стоит
                                                </div>
                                            </div>
                                        </>) : null}
                                    </>) : (<>
                                        {/* CLIENT TASK EDIT: START */}
                                        <form className={"grid grid-cols-12 gap-2 border border-gray-200 px-4 py-4 shadow"} onSubmit={editTaskSubmit(onEditTaskSubmit)} onReset={() => onEditTaskReset()}>
                                            <div className={"col-span-6"}>
                                                <label>Дата начала</label>
                                                <input type={"text"} {...editTaskRegister("date")}/>
                                            </div>

                                            <div className={"col-span-6"}>
                                                <label>Дата завершения</label>
                                                <input type={"text"} {...editTaskRegister("ends")}/>
                                            </div>

                                            <div className={"col-span-12"}>
                                                <label>Требуется найти кандидатов</label>
                                                <input type={"text"} {...editTaskRegister("target_users")}/>
                                            </div>


                                            <button className={"btn white col-span-6"} type={"reset"}>Отменить</button>
                                            <button className={"btn col-span-6"} type={"submit"}>Сохранить</button>
                                        </form>
                                        {/* CLIENT TASK EDIT: END */}
                                    </>)}

                                </div>
                            </>) : null}

                        </div>

                        <div className={"col-span-6"}>
                            <span className={"text-2xl"}>Кандидаты</span>

                            <Link className={"btn w-full px-0 mx-0 mt-2"} to={`/agency/${id}/search`}>Искать нового</Link>

                            {/* CANDIDATES FILTER & COUNT START */}
                            {userEditorProfile.data.agency_client_matches ? <>
                                <div className={"grid grid-cols-12 gap-2 mt-2"}>
                                    <div className={"col-span-6"}>
                                        <label>Фильтр по статусу</label>
                                        <select value={candidateFilter} onChange={e => setCandidateFilter(e.target.value)}>
                                            <option value="">Все</option>
                                            {candidateStatuses.map(val => (
                                                <option value={val}>{ttAgencyCandidateStatus(val)}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={"col-span-6"}>
                                        <label>Фильтр по телефону</label>
                                        <input type={"text"} value={candidateFilterTel} onChange={e => setCandidateFilterTel(e.target.value)}/>
                                    </div>
                                    <div className={"col-span-6"}>
                                        <div>Отображено {userEditorProfile
                                            .data.agency_client_matches
                                            .filter(m => (candidateFilter === "" || m.status === candidateFilter))
                                            .filter(m => (candidateFilterTel === "" || m.target.tel.indexOf(candidateFilterTel) !== -1))
                                            .length} из {userEditorProfile.data.agency_client_matches.length}</div>
                                    </div>
                                </div>
                            </> : null}
                            {/* CANDIDATES FILTER & COUNT END */}

                            {userEditorProfile.data.agency_client_matches ? userEditorProfile.data.agency_client_matches.filter(m => (candidateFilterTel === "" || m.target.tel.indexOf(candidateFilterTel) !== -1)).filter(m => (candidateFilter === "" || m.status === candidateFilter)).map(match => (
                                <div key={match.id}>
                                    {/* USER SMALL PROFILE START */}
                                    <div className={"grid lg:grid-cols-12 grid-cols-4 gap-2 my-4 border border-gray-200 shadow"}>
                                        <div className={"lg:col-span-3 col-span-4"}>
                                            {match.target.photos && match.target.photos.length ? (
                                                <img className={"w-full"}
                                                     src={`${PHOTOS_BASE_URL}z${match.target.photos[0].dir}/${match.target.photos[0].thumb}`}
                                                     alt={""}/>
                                            ) : (
                                                <div className={"square w-full bg-gray-400"}/>
                                            )}
                                        </div>
                                        <div className={"lg:col-span-9 col-span-4"}>
                                            <Link to={`/users/${match.target.id}/view`} className={"text-xl"}>{match.target.display_name}</Link>
                                            <div className={"text-xs text-gray-500"}>
                                                {ttBirthAge(match.target.birth, match.target.age, match.target.birth_year)}
                                            </div>

                                            <div className={"pt-2"}>
                                                <span className={"text-gray-500"}>Контактные данные: </span>
                                                {telToText(match.target.tel)}
                                                {match.target.email ? `, ${match.target.email}` : null}
                                            </div>

                                            <div className={"pt-2"}>
                                                {/* MATCH STATUS DISPLAY: START */}
                                                {clientStatus.match_id !== match.match_id ? (<>
                                                    <div className={"text-gray-500"}>Статус кандидата</div>
                                                    <div className={"pt-2 cursor-pointer"} onClick={() => onEditMatchActivate(match)}>
                                                        <div className={"flex"}>
                                                            <div className={""}>
                                                                {["dislike", "not_interested"].indexOf(match.status) >= 0 ? (<span className={"icon text-red-500"}>cancel</span>) : null}
                                                                {["candidate", "ready", "phones", "planning"].indexOf(match.status) >= 0 ? (<span className={"icon text-yellow-500"}>update</span>) : null}
                                                                {["meeting", "wedding"].indexOf(match.status) >= 0 ? (<span className={"icon text-green-500"}>check_circle</span>) : null}
                                                            </div>
                                                            <div className={"pl-2"}>
                                                                <div>{moment(match.date).format("DD.MM.YYYY")}</div>
                                                                <div>{ttAgencyCandidateStatus(match.status)}: {match.comment}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>) : null}
                                                {/* MATCH STATUS DISPLAY: END */}

                                                {/* MATCH STATUS EDIT: START */}
                                                {clientStatus.match_id === match.match_id ? (<>
                                                    <form className={"grid grid-cols-12 gap-2"} onSubmit={sumMatch(onEditMatchSubmit)} onReset={onEditMatchReset}>
                                                        <div className={"col-span-6"}>
                                                            <label>Статус кандидата</label>
                                                            <select {...regMatch('status')}>
                                                                {candidateStatuses.map(val => (
                                                                    <option value={val}>{ttAgencyCandidateStatus(val)}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className={"col-span-6"}>
                                                            <label>Комментарий</label>
                                                            <input type={"text"} {...regMatch('comment')}/>
                                                        </div>

                                                        <div className={"col-span-12 text-right my-2"}>
                                                            <button type={"button"} className={"btn red"} onClick={() => deleteCandidate(match.user_id, match.match_id)}>Удалить</button>
                                                            <button type={"reset"} className={"btn white"}>Отменить</button>
                                                            <button type={"submit"} className={"btn"}>Сохранить</button>
                                                        </div>
                                                        <input type={"hidden"} {...regMatch('match_id', {value: match.match_id})}  />
                                                    </form>
                                                </>) : null}
                                                {/* MATCH STATUS EDIT: END */}
                                            </div>

                                        </div>

                                    </div>

                                    {/* USER SMALL PROFILE END */}

                                </div>
                            )) : null}


                        </div>
                    </div>
                </div>
                {/* VIEW: AGENCY: END */}

            </>) : null}

            {/* VIEW END */}


        </>)
}
