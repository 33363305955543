import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectUserEditorList, selectUserEditorListQuery, userListQuerySet, performUserListQuery, performUserCreate, selectUserCreateState, userListReset} from '../reducers/userEditor'
import {User, UserListFilter} from "../types/spedddating";
import UserPreviewBig from "../components/UserPreviewBig";
import UserFilterForm from "../components/UserFilterForm";
import {Link} from "react-router-dom";
import {getLoginState} from "../reducers/login";
import {ttAgencyStatus} from "../types/toText";
import moment from "moment";
import {useForm} from "react-hook-form";
import {performUserSearch, resetUserSearchResults, selectUserSearchResult} from "../reducers/users";
import cx from 'classnames';
import Error from "../components/error";

export default function UserEditor() {
    const dispatch = useDispatch();

    const userListQuery = useSelector(selectUserEditorListQuery);
    const userList = useSelector(selectUserEditorList);
    const loginState = useSelector(getLoginState);

    const onReload = (filter: UserListFilter, page: number) => {
        let offset = Math.ceil(page * userListQuery.limit);
        dispatch(userListQuerySet({filter, offset: offset, order: "-id"}));
        dispatch(performUserListQuery());
    }

    useEffect(() => {
        // On reload: reset data
        dispatch(userListReset());
    }, []);

    // NEW USER START

    const userSearchResult = useSelector(selectUserSearchResult);
    const userCreateState = useSelector(selectUserCreateState);

    const {register, watch, formState: {errors}, handleSubmit} = useForm<User>({});
    const _tel = watch("tel", "");

    const [tel, setTel] = useState("");
    if (tel !== _tel) {
        if (_tel.length >= 3)
            dispatch(performUserSearch({tel: _tel}));
        else
            dispatch(resetUserSearchResults());
        setTel(_tel);
    }

    const onUserCreate = (user: User) => {
        dispatch(performUserCreate(user));
    }

    useEffect(() => {
        if (userCreateState.status === "complete") {
            if (userCreateState.user && userCreateState.user.id)
                document.location.href = `/agency/${userCreateState.user.id}/view`;
        }
    }, [userCreateState.status])
    // NEW USER END

    return (
        <>
            <div className={'relative'}>
                <h1>Брачное агентство</h1>

                <div className={'lg:absolute lg:right-0 lg:top-4'}>
                    <a className={'btn green'} href={'/expo/list'}>Каталог - анкеты</a>
                    <a className={'btn red'} href={'/expo/access'}>Каталог - доступы</a>
                </div>
            </div>
            <UserFilterForm onUpdate={onReload} mode={"agency"}>
                <div className="grid grid-rows-1 grid-cols-12 gap-4">
                    <div className="col-start-1 col-end-13">
                        <div className="w-container2">
                            <h2 className={"mt-4 pb-0"}>Клиенты брачного агентства {userList.status === "fetching" ? "(...обновление...)" : null}</h2>

                            {userList.error !== "" ? (<>
                                <Error error={userList.error}/>
                            </>) : null}

                            {/* NEW USER START */}
                            <div className={"w-container"}>
                                <form onSubmit={handleSubmit(onUserCreate)}>
                                    <div className={"grid grid-cols-12 gap-2"}>
                                        <div className={"col-span-2"}>
                                            <label>Телефон (поиск)</label>
                                            <input type={"text"} className={errors.tel ? "error" : ""} {...register('tel', {
                                                required: true,
                                                pattern: /^(?!8\d{10}$)\d+$/,
                                            })}/>
                                            {errors.tel ? (<div className={"text-red-600 text-sm"}>
                                                Телефонные номера в РФ начинаются с 7 и содержат 11 цифр, например 79035551122
                                            </div>) : null}
                                        </div>
                                        <div className={"col-span-2"}>
                                            <label>E-mail</label>
                                            <input type={"text"} className={errors.email ? "error" : ""} {...register('email', {
                                                required: false,
                                                validate: val => (!val || !!val.match(/^[^\@]+\@.+\..+$/)),
                                            })}/>
                                            {errors.email ? (<div className={"text-red-600 text-sm"}>
                                                Это не похоже на емейл!
                                            </div>) : null}
                                        </div>

                                        <div className={"col-span-2"}>
                                            <label>Имя</label>
                                            <input type={"text"} className={errors.display_name ? "error" : ""} {...register('display_name', {
                                                required: true,
                                            })}/>
                                            {errors.display_name ? (<div className={"text-red-600 text-sm"}>
                                                Имя должно быть задано
                                            </div>) : null}
                                        </div>

                                        <div className={"col-span-2"}>
                                            <label>Д.Р.</label>
                                            <input type={"text"} className={errors.birth ? "error" : ""}  {...register('birth', {
                                                required: false,
                                                validate: (val) => (!val || !!val.match(/^\d{4}\-\d{2}\-\d{2}/))
                                            })}/>
                                            {errors.birth ? (<div className={"text-red-600 text-sm"}>
                                                Дата рождения должна быть в формате ГГГГ-ММ-ДД
                                            </div>) : null}
                                        </div>

                                        <div className={"col-span-2"}>
                                            <label>Пол</label>
                                            <select className={errors.gender ? "error" : ""} {...register("gender", {
                                                required: true,
                                                validate: (val) => (val && val !== 'u')
                                            })}>
                                                <option value={"u"}>Не задан</option>
                                                <option value={"m"}>М</option>
                                                <option value={"f"}>Ж</option>
                                            </select>
                                            {errors.gender ? (<div className={"text-red-600 text-sm"}>
                                                Не выбран
                                            </div>) : null}
                                        </div>

                                        <div className={"col-span-2"}>
                                            <label>&nbsp;</label>
                                            <button className={"btn"} type={"submit"}>Сохранить</button>
                                            <button className={"btn white"} type={"reset"}>Сбросить</button>
                                        </div>
                                    </div>
                                </form>

                                {userSearchResult.items && userSearchResult.items.length ? (<>
                                    <div className={"col-span-12"}>
                                        {userCreateState.error ? (<Error error={userCreateState.error} />) : null}

                                        <h3>Результаты поиска</h3>

                                        {userSearchResult.items.map(user => {
                                            const classes = cx({
                                                'grid': true,
                                                'grid-cols-12': true,
                                                'gap-2': true,
                                                'cursor-pointer': !user.is_agency_client,
                                                'cursor-not-allowed': user.is_agency_client,
                                                'hover:bg-gray-200': true
                                            })
                                            return (<div key={user.id}>
                                                <Link className={classes} onClick={() => dispatch(resetUserSearchResults())} to={`/agency/${user.id}/view`}>
                                                    <div className={"col-span-2"}>{user.tel}</div>
                                                    <div className={"col-span-2"}>{user.email}</div>
                                                    <div className={"col-span-2"}>{user.display_name}</div>
                                                    <div className={"col-span-2"}>
                                                        {user.deleted ? (
                                                            <span className={"bg-black text-white rounded px-2 mr-1"}>УД</span>
                                                        ) : null}

                                                        {user.is_agency_client ? (
                                                            <span
                                                                className={"bg-purple-600 text-white rounded px-2 mr-1"}>БА</span>
                                                        ) : null}

                                                        {user.is_speeddating_client ? (
                                                            <span
                                                                className={"bg-green-600 text-white rounded px-2 mr-1"}>СВ</span>
                                                        ) : null}
                                                    </div>
                                                </Link>
                                            </div>)
                                        })}

                                    </div>
                                </>) : null}
                            </div>
                            {/* NEW USER END */}

                            {userList.items.length > 0 ? userList.items.map(user => (
                                <Link to={`/agency/${user.id}/view`} key={user.id}>
                                    <UserPreviewBig key={user.id} user={user} agency={!!loginState.admin_privilege.agency} buttons={<>
                                        <Link className={"btn mb-1 block text-center"} to={`/users/${user.id}/view`}>Профиль</Link>
                                    </>} col2={<>
                                        {user.agency_client ? (<>
                                            <div>
                                                <span className={"text-gray-500"}>Менеджер: </span>
                                                {user.agency_client.manager?.display_name || "Не задан"}
                                            </div>
                                            <div>
                                                <span className={"text-gray-500"}>Договор: </span>
                                                {ttAgencyStatus(user.agency_client.status)}
                                            </div>
                                            <div>
                                                <span className={"text-gray-500"}>Задача: </span>

                                                {user.agency_client.status === "contract" && user.agency_client_tasks && user.agency_client_tasks.length ? (<>
                                                    Найти {user.agency_client_tasks[0].target_users - user.agency_client_tasks[0].target_found} из {user.agency_client_tasks[0].target_users} до {moment(user.agency_client_tasks[0].ends).format("DD.MM.YYYY")}
                                                </>) : (<>
                                                    Отсутствует
                                                </>)}
                                            </div>
                                        </>) : (<></>)}
                                    </>}/>
                                </Link>
                            )) : null}
                        </div>
                    </div>
                </div>
            </UserFilterForm>
        </>)
}
