import {performLogout, getLoginState} from '../reducers/login'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from "react-router";
import {useEffect} from "react";

export default function Logout() {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginState = useSelector(getLoginState);

    if (loginState.status === 'authenticated') {
        dispatch(performLogout());
    }

    useEffect(() => {
        if (loginState.status === 'unauthenticated') {
            history.replace('/');
            dispatch({type: 'RESET'});
        }
    })

    return (<>
        Logging out...
    </>);
}
